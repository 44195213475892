/*--------------------------------------------------------*\
|  ██████   ██  |
|  ██  ██   ██  |
|  ██  ██   ██  |
|  ██████   ██  |  binary : tech art
|
|  Site themes.
|----------------------------------------------------------
|  Copyright(C) 2021 Valeriy Novytskyy
\*---------------------------------------------------------*/

import { createGlobalStyle } from 'styled-components';
import { MOBILE, NARROW_NO_RULERS } from './constants';

const unit = 30;

const spacing = {
  unit: unit,
  border: '2px',
  borderThick: '3px',
  borderRadius: '4px',
  spacing: `${unit}px`,
  spacingDouble: `${unit * 2}px`,
  spacingTriple: `${unit * 3}px`,
  spacingOneAndHalf: `${unit * 1.5}px`,
  spacingOneAndThird: `${(unit * 4) / 3}px`,
  spacingFourThirds: `${unit / 4 / 3}px`,
  spacingThreeHalves: `${unit / 3 / 2}px`,
  spacingHalf: `${unit / 2}px`,
  spacingThird: `${unit / 3}px`,
  spacingQuarter: `${unit / 4}px`,
  spacingSmall: `${unit / 6}px`,
  spacingMin: `${unit / 12}px`,
  cornerSmall: `${unit / 3}px`,
  margin: `11.25pt`,
  column: '950px',
};

const typography = {
  titleFont: 'Consolas, Menlo, monospace',
  titleFontSize: '16pt',
  titleFontWeight: 'normal',

  headingFont:
    'Open Sans, Helvetica Neue, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif',
  headingFontWeight: 600,
  headingFontSizeLargest: '22pt',
  headingFontSizeLarge: '20pt',
  headingFontSizeMedium: '16pt',
  headingFontSizeSmall: '15pt',
  headingFontSizeSmaller: '14.375pt',

  normalFont:
    '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
  normalFontWeight: 400,
  normalFontSize: '15pt',
  normalFontLineHeight: '1.52em',
  navigationFontSize: '12.5pt',

  smallFont: 'Segoe UI, Verdana, -apple-system, BlinkMacSystemFont, sans-serif',
  smallFontWeight: 400,
  smallFontSize: '12.5pt',
  smallFontLineHeight: '21.25pt',
  smallFontLineHeightMobile: '1.4em',

  smallestFontSize: '10pt',

  fallbackFont: 'sans-serif',
  backgroundFont: 'Arial',
};

const animations = {
  animationFast: '.3s',
  animationSlow: '.5s',
};

const sharedTheme = {
  ...spacing,
  ...typography,
  ...animations,
};

export const lightTheme = {
  primaryColor: '#12C0E1',
  primaryHoverColor: '#19C9EA',
  primaryLightColor: '#71D3DB',
  primaryDarkColor: '#0E93AD',
  primaryDarkShadowColor: '#10A6C4',
  primaryTextColor: '#FFFFFF',

  secondaryColor: '#A158BA',
  secondaryTextColor: '#848D95',

  accentColor: '#E3EBEC',
  accentLightColor: '#F6F8FA',
  accentHighlightColor: '#FBFBFB',
  accentLightShadowColor: '#EBEBEB',
  accentDarkShadowColor: '#666666',
  accentShadowColor: '#898989',
  accentHoverColor: '#D7DFE0',
  accentTextColor: '#127191',

  alwaysDarkColor: '#3B4351',
  alwaysShadowColor: '#1E1E1E',
  alwaysLightColor: '#FFFFFF',

  foregroundColor: '#3B4A51',
  backgroundColor: '#FFFFFF',
  borderColor: '#AAAAAA',
  selectionColor: '#C3C3C3',

  shadowColor: '#D3D3D3',
  shadowDarkColor: '#595959',
  shadowLightColor: '#B5D9E3',

  dropShadowDarkColor: '#777777',
  dropShadowLightColor: '#999999',
  dropShadowTransparentColor: '#000000',

  warningColor: '#E1B412',
  focusColor: '#FF008E',
  errorColor: '#EE8A83',
  successColor: '#43A39F',

  textSelectionColor: 'white',
  textSelectionBackground: '#FF008E',

  ...sharedTheme,
};

export const darkTheme = {
  primaryColor: '#12C0E1',
  primaryHoverColor: '#19C9EA',
  primaryLightColor: '#71D3DB',
  primaryDarkColor: '#0E93AD',
  primaryDarkShadowColor: '#10A6C4',
  primaryTextColor: '#FFFFFF',

  secondaryColor: '#A158BA',
  secondaryTextColor: '#c6c6c6',

  accentColor: '#E3EBEC',
  accentLightColor: '#4E4F56',
  accentHighlightColor: '#393940',
  accentLightShadowColor: '#28292B',
  accentDarkShadowColor: '#000000',
  accentShadowColor: '#898989',
  accentHoverColor: '#D7DFE0',
  accentTextColor: '#127191',

  alwaysDarkColor: '#3B4351',
  alwaysShadowColor: '#1E1E1E',
  alwaysLightColor: '#FFFFFF',

  foregroundColor: '#ffffff',
  backgroundColor: '#2B2C34',
  borderColor: '#8E8E8E',
  selectionColor: '#C3C3C3',

  shadowColor: '#D3D3D3',
  shadowDarkColor: '#1E1E1E',
  shadowLightColor: '#B5D9E3',

  dropShadowDarkColor: '#1E1E1E',
  dropShadowLightColor: '#999999',
  dropShadowTransparentColor: 'black',

  warningColor: '#E1B412',
  focusColor: '#FF008E',
  errorColor: '#EE8A83',
  successColor: '#43A39F',

  textSelectionColor: 'white',
  textSelectionBackground: '#FF008E',

  ...sharedTheme,
};

export const GlobalStyle = createGlobalStyle`
  body {
    background: ${(props) => props.theme.backgroundColor};
    transition: background-color ${(props) =>
      props.theme.animationFast} ease-out;
  }

  @media (max-width: ${MOBILE}) {
    body {
      margin-top: ${unit * 2.5}px;
    }
  }

  h1 {
    text-transform: lowercase;
    font-size: ${(props) => props.theme.headingFontSizeLarge};
    font-family: ${(props) => props.theme.headingFont};
    font-weight: ${(props) => props.theme.headingFontWeight};
    padding-top: 0;
    margin-bottom: ${(props) => props.theme.margin};
    margin-top: ${(props) => props.theme.margin};
  }

  h2, h3, h4 {
    text-transform: lowercase;
    font-family: ${(props) => props.theme.headingFont};
    font-weight: ${(props) => props.theme.headingFontWeight};
    margin-left: ${(props) => props.theme.spacingHalf};
    margin-right: ${(props) => props.theme.spacingHalf};
  }

  main {
    position: relative;
    max-width: ${(props) => props.theme.column};
    margin: auto;
    font-family: ${(props) => props.theme.normalFont};
    font-weight: ${(props) => props.theme.normalFontWeight};
    font-size: ${(props) => props.theme.normalFontSize};
    color: ${(props) => props.theme.foregroundColor};

    &:before {
      content: '{';
      font-family: ${(props) => props.theme.backgroundFont};
      font-size: 45pt;
      color: ${(props) => props.theme.borderColor};
  
      float: left;
      position: relative;
      right: ${(props) => props.theme.spacingOneAndHalf};
      top: 26pt;
      width: 0;
    }

    &:after {
      content: '}';
      font-family: ${(props) => props.theme.backgroundFont};
      font-size: 45pt;
      color: ${(props) => props.theme.borderColor};
  
      float: right;
      position: relative;
      left: calc(
        ${(props) => props.theme.spacingOneAndHalf} + ${(props) =>
  props.theme.border});
      text-align: right;
    }

    @media(max-width: ${NARROW_NO_RULERS}) {
      &:before, &:after {
        content: '';
      }
    }
  }

  main p, footer p, article p {
    color: ${(props) => props.theme.foregroundColor};
    line-height: ${(props) => props.theme.normalFontLineHeight};
    margin-left: ${(props) => props.theme.spacingHalf};
    margin-right: ${(props) => props.theme.spacingHalf};
  }

  main a, footer a, article a {
    position: relative;
    color: ${(props) =>
      props.theme.isDark
        ? props.theme.primaryColor
        : props.theme.accentTextColor};
    text-decoration: none;
    transition: color ${(props) => props.theme.animationFast} ease-out;

    &:focus {
      border-radius: ${(props) => props.theme.borderRadius};
      box-shadow: 0 0 0 ${(props) => props.theme.border} ${(props) =>
  props.theme.focusColor};
      outline: none;
    }
  
    &:hover {
      color: ${(props) =>
        props.theme.isDark
          ? props.theme.primaryLightColor
          : props.theme.primaryDarkColor};
      text-decoration: underline;
    }
  }

  main li {
    line-height: ${(props) => props.theme.normalFontLineHeight};
  }

  main img {
    box-shadow: none !important;
  }

  ::selection {
    color: ${(props) => props.theme.backgroundColor};
    background: ${(props) => props.theme.focusColor};
  }

  .hide {
    position: absolute !important;
    top: -9999px !important;
    left: -9999px !important;
  }

  .fill-background {
    fill: ${(props) => props.theme.accentShadowColor};
  }

  .fill-alwaysdark {
    fill: ${(props) => props.theme.alwaysDarkColor};
  }

  .fill-background--light {
    fill: ${(props) =>
      props.theme.isDark
        ? props.theme.dropShadowDarkColor
        : props.theme.accentLightShadowColor};
  }

  .fill-background--medium {
    fill: ${(props) =>
      props.theme.isDark ? props.theme.borderColor : '#B7B7B7'};
  }

  .stroke-background--medium {
    stroke: ${(props) =>
      props.theme.isDark ? props.theme.borderColor : '#B7B7B7'};
  }

  .fill-foreground {
    fill: ${(props) => props.theme.foregroundColor};
  }

  .fill-focus {
    fill: ${(props) => props.theme.focusColor};
  }

  .fill-none {
    fill: none;
  }

  .stroke-foreground {
    stroke: ${(props) => props.theme.foregroundColor};
  }

  .stroke-background {
    stroke: ${(props) => props.theme.accentShadowColor};
  }

  .stroke-opaque {
    stroke: ${(props) => props.theme.backgroundColor};
  }

  .stroke-border {
    stroke: ${(props) => props.theme.borderColor};
  }

  .stroke-secondary {
    stroke: ${(props) => props.theme.secondaryTextColor};
  }

  .fill-secondary {
    fill: ${(props) => props.theme.secondaryTextColor};
  }

  .stroke-primary {
    stroke: ${(props) => props.theme.primaryColor};
  }

  .stroke-alternate {
    stroke: ${(props) => props.theme.secondaryColor};
  }

  .stroke-error {
    stroke: ${(props) => props.theme.errorColor};
  }

  .stroke-success {
    stroke: ${(props) => props.theme.successColor};
  }

  .fill-border {
    fill: ${(props) =>
      props.theme.isDark
        ? props.theme.borderColor
        : props.theme.accentShadowColor};
  }

  .fill-opaque {
    fill: ${(props) => props.theme.backgroundColor};
  } 

  .stroke-focus {
    stroke: ${(props) => props.theme.focusColor};
  }

  .stroke-none {
    stroke: none;
  }

  .light-only {
    display: ${(props) => (props.theme.isDark ? `none` : `block`)};
    max-width: 700px;
  }

  .dark-only {
    display: ${(props) => (props.theme.isDark ? `block` : `none`)};
    max-width: 700px;
  }

  .margin-bottom-half {
    margin-bottom: ${(props) => props.theme.spacingHalf};
  }

  .autocomplete {
    font-family: ${(props) => props.theme.smallFont};
    font-size: ${(props) => props.theme.smallFontSize};
    font-weight: ${(props) => props.theme.smallFontWeight};

    color: ${(props) => props.theme.foregroundColor};
    background: ${(props) => props.theme.backgroundColor};
    
    padding: ${(props) => props.theme.spacingQuarter};
    border: ${(props) => props.theme.border} solid ${(props) =>
  props.theme.borderColor};
    border-radius: ${(props) => props.theme.borderRadius};
    margin-top: calc(0px - ${(props) => props.theme.border});

    div {
      cursor: pointer;
      padding: ${(props) => props.theme.spacingQuarter};
    }
  }

  #nprogress {
    height: 6px;
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    right: 0;

    .bar {
      height: 100%;
      background: linear-gradient(
        90deg,
        ${(props) => props.theme.focusColor} 0%,
        ${(props) => props.theme.secondaryColor} 100%
      );
    }
  }
`;
