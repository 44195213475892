import React, { FC } from 'react';
import styled from 'styled-components';

const BeavisAndButtheadIllustration: FC<{ className?: string }> = ({
  className,
}) => (
  <svg width="380" viewBox="0 0 338.4 253.2" className={className}>
    <g id="background">
      <path
        fill="none"
        stroke="#CECECE"
        strokeMiterlimit="10"
        d="M152.8,145.3c10.6,0,19.1,8.6,19.1,19.1c0,10.6-8.6,19.1-19.1,19.1
		H74.2c-10.6,0-19.1,8.6-19.1,19.1s8.6,19.1,19.1,19.1H282c10.6,0,19.1-8.6,19.1-19.1s-8.6-19.1-19.1-19.1h-52.6
		c-10.6,0-19.1-8.6-19.1-19.1c0-10.6,8.6-19.1,19.1-19.1l71.8,0c10.6,0,19.1-8.6,19.1-19.1s-8.6-19.1-19.1-19.1H191.1
		c-10.6,0-19.1-8.6-19.1-19.1s8.6-19.1,19.1-19.1h56.1c10.6,0,19.1-8.6,19.1-19.1s-8.6-19.1-19.1-19.1H68.5
		c-10.6,0-19.1,8.6-19.1,19.1s8.6,19.1,19.1,19.1h46c10.6,0,19.1,8.6,19.1,19.1s-8.6,19.1-19.1,19.1H38c-10.6,0-19.1,8.6-19.1,19.1
		s8.6,19.1,19.1,19.1L152.8,145.3z"
      />
    </g>
    <g id="chortle1">
      <path
        stroke="#000000"
        strokeMiterlimit="10"
        d="M121.5,187c0,0,2.4,2.9,4.8,3.7c2.4,0.8,9.6,2.3,9.6,7.9s0,11.2,0,11.2h-5.6V222
		H88.4v-8.3h-7.6c0,0,0-4,0.4-11.2c0.4-7.1,7.7-8.7,10.6-9.6s10.3-5.1,10.3-5.1"
      />
      <path
        stroke="#000000"
        strokeMiterlimit="10"
        d="M257.1,187.2c0,0,8.5,0.9,10.6,5.3c2.3,4.8,2.1,16.9,2.1,16.9l-8,0.4l0.3,12.1
		l-33.9,0.2l0.6-11.7h-5.9c0,0,0.6-10,0.6-12.6s3.8-4,6.6-5.1c3.7-1.4,6.2-2.5,7.2-3.2C238.2,188.7,257.1,187.2,257.1,187.2z"
      />
      <path
        d="M140.7,124.7c0,0,4.5-3.7,4.9-8c0.4-4.4,1.5-25.4,0.1-30.7c-1.4-5.3-5-7.4-5-11.7c0,0,1.3-4.4,0-6.8
		c-1.3-2.4-0.4-11.2-4.1-11.2s-4.4,4.5-6.5,1.7s-4-8-8.3-10s-10.7-2.4-13-4c-2.3-1.6-17.2-9.8-18.2,0s0,13.9-1.1,15.3
		s-3.4,3.1-3.8,6.1c-0.4,3-2.7,5.1-3.5,8.2c-0.8,3.1-1.1,9.7-1.3,18.8s-1,15.5,0.6,21.5s2.5,9.7,3.2,12"
      />
      <path
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M103.1,192.7
		c0,0-2.3-12.4-4-17.9s-6.9-19.9-8.5-26.5c-1.6-6.7-3.6-14.9-3.6-14.9s-3.8-3-3.2-5.8c0.6-2.8,3.2-3.8,3.2-3.8s-1.7-13.6-1.7-16.8
		c0-3.2-1-9.3,0-9.2c3.5,0.4,11.9-13.3,15.5-10.9c2.5,1.7,5.7,7.4,11,7.2s11.1-6.4,12-8c0.9-1.5,4.3-2.4,6.2-1.1s2.4-2.3,5.5-1.1
		c3,1.1,5.8,6.4,5.6,14.3c-0.2,7.9-0.6,26.5-0.6,26.5s2.1-0.9,2.1,2.1c0,3-0.8,4.9-2.1,5.3s-1.3,0.4-1.3,0.4s-2.4,11.5-6.8,20.7
		s-9.6,20.7-10.1,24.2s-1.6,15-1.6,15s-7.5,1-10.2,0.8C108.1,193.1,103.1,192.7,103.1,192.7z"
      />
      <path
        stroke="#000000"
        strokeMiterlimit="10"
        d="M102.4,158.1c-0.7,2,3.5,0.2,5.6,1.2c2.1,1,2.7,2.1,5.9,2.1
		c3.2,0,3.6-2.2,7.2-2.6s4.4-1.1,3.9-2.4s-4.7-8.8-10.6-8.8S103.1,156.1,102.4,158.1z"
      />
      <path
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M122.3,152.2
		c-1.9-2.3-4.6-4.7-7.9-4.7c-3.1,0-5.9,2.8-8.1,5.3L122.3,152.2z"
      />
      <path
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M105,171.4
		c0,0,0.8,2.1,4.3,2.1c3.6,0,7,0,7,0"
      />
      <path
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M99.7,147.5
		c0,0-1.7,3.4-1.9,5.6c-0.2,2.3,0,4.3,0,4.3"
      />
      <path
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M126.4,149.6
		c0,0,1.3,1.1,1.5,2.9c0.2,1.8,0,4,0,4"
      />
      <path
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M118,117.6
		c0,0,1.3,3.8,1.3,5.5s0,4.1,0,4.1"
      />
      <path
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M140.7,127.2
		c0,0-0.8,3.5,0,2.8"
      />
      <path
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M86.9,126.7
		c0,0,1.4,5.2,0,3.5"
      />
      <path
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M104.1,111.9
		c0,0,1.6,0.1,3.4,0.9c1.8,0.8,2.8,1.7,3.1,2.7"
      />
      <path
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M131,113
		c0,0-3.8-0.6-5.7,1.5c-1.6,1.7-1.2,4-1.2,4"
      />
      <path
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M109.1,128.6
		c0,0-3.3-1-5,1.5c-0.9,1.4-0.6,4.5-0.6,4.5"
      />
      <path
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M122.4,129.3
		c1.7-1.5,5.8,0.7,3.4,4.4"
      />
      <path d="M105.6,135.6c-0.5-0.1,0.2-2.9,2.3-3.1s2.8,0.8,2.6,2.3c-0.2,1.4-0.5-1.4-2.3-0.7C107.1,135.1,106.8,135.6,105.6,135.6z" />
      <path
        d="M125.6,134.6c0.5-1.1-0.1-2.3-2-2.4c-1.9-0.2-2.3,0.6-2.7,2.1c-0.3,1.3,0.7-1.3,2.3-0.6
		C124.3,134.6,124.6,134.6,125.6,134.6z"
      />
      <path
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M110.5,134.7
		c0,0-0.1,5.8,1.6,7.8c0.6,0.7,3.7,1.8,5.6-2c1.9-3.8,3.5-6.3,3.5-6.3"
      />
      <path
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M127.6,117.8
		c1.7-1,3.2-1.3,2.5-1.9c-0.7-0.6-2.7-1.8-3.7-0.3C124.8,118,125.3,119.2,127.6,117.8z"
      />
      <path
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M109.1,120
		c-1,0-0.4-0.4-2.3-1s-3.5-1.6-2.8-2.2s2.8-1.1,4.2,0C110,118.1,110.2,120,109.1,120z"
      />
      <path
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M118,117.6
		c0,0,1.3,3.8,1.3,5.5s0,4.1,0,4.1"
      />
      <path
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M104.1,111.9
		c0,0,1.6,0.1,3.4,0.9c1.8,0.8,2.8,1.7,3.1,2.7"
      />
      <path
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M131,113
		c0,0-3.8-0.6-5.7,1.5c-1.6,1.7-1.2,4-1.2,4"
      />
      <path
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M109.1,128.6
		c0,0-3.3-1-5,1.5c-0.9,1.4-0.6,4.5-0.6,4.5"
      />
      <path
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M122.4,129.3
		c1.7-1.5,5.8,0.7,3.4,4.4"
      />
      <path d="M105.6,135.6c-0.5-0.1,0.2-2.9,2.3-3.1s2.8,0.8,2.6,2.3c-0.2,1.4-0.5-1.4-2.3-0.7C107.1,135.1,106.8,135.6,105.6,135.6z" />
      <path
        d="M125.6,134.6c0.5-1.1-0.1-2.3-2-2.4c-1.9-0.2-2.3,0.6-2.7,2.1c-0.3,1.3,0.7-1.3,2.3-0.6
		C124.3,134.6,124.6,134.6,125.6,134.6z"
      />
      <path
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M110.5,134.7
		c0,0-0.1,5.8,1.6,7.8c0.6,0.7,3.7,1.8,5.6-2c1.9-3.8,3.5-6.3,3.5-6.3"
      />
      <path
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M127.6,117.8
		c1.7-1,3.2-1.3,2.5-1.9c-0.7-0.6-2.7-1.8-3.7-0.3C124.8,118,125.3,119.2,127.6,117.8z"
      />
      <path
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M109.1,120
		c-1,0-0.4-0.4-2.3-1s-3.5-1.6-2.8-2.2s2.8-1.1,4.2,0C110,118.1,110.2,120,109.1,120z"
      />
      <path
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M107.9,160.8
		c1.3,0.4,1.2,2.5,5.4,2.8s4.8-2.5,6.5-3.2"
      />
      <polygon
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
		124.3,155 104.1,155.6 106.3,152.8 122.3,152.2 	"
      />
      <path
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M110.2,151
		c0,0,0.9-1.3,3.7-1.9"
      />

      <line
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="115.8"
        y1="152.5"
        x2="116.8"
        y2="155.2"
      />

      <line
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="119.7"
        y1="152.5"
        x2="120.7"
        y2="155.1"
      />
      <path
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M81.8,213.7
		c0,0-0.6,7,0,8.3"
      />
      <path
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M134.9,209.8
		c0,0,0.4,3.5,0,6.1c-0.4,2.6-0.4,6.1-0.4,6.1"
      />

      <line
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="108.3"
        y1="152.7"
        x2="107"
        y2="155.2"
      />

      <line
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="112.6"
        y1="152.6"
        x2="111.7"
        y2="155.2"
      />
      <path
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M255.2,165.1
		c0,0,1.7,0.4,2.3-1.6c0.6-2,9.3-34.2,9.6-35.9c0.3-1.7,1.3-10,1.6-11.3c0.3-1.3,2-5.1,1.8-7.1c-0.1-2-0.6-5.4-0.3-6.8
		c0.3-1.4,1.3-4.2,0.8-8c-0.4-3.8-1.7-6.4-1.4-8s0.6-8.9-0.6-11.6c-1.1-2.7-3.5-6.1-4.2-6.1s-0.1-3.2-2.4-5.5
		c-2.3-2.3-5.8-3.1-5.8-3.1s-1.3-3.8-3.7-4.9s-4.1-0.1-4.1-0.1s-4.8-5.5-8.2-4.9s-5.2,3.5-5.2,3.5s-3.1-0.6-4.8-1.1
		c-1.7-0.6-4.4,0-4.4,0s-5.2-3.4-7.9-2.8s-5.6,4.2-5.6,4.2s-4.2-0.3-6.9,2.3c-2.7,2.5-3.2,6.1-3.2,6.1s-4.4-0.1-6.5,3
		c-2.1,3.1-2.1,6.5-2.1,6.5l-0.3,5.1c0,0-3.2,1.3-3.1,5.2s2,6.9,2.8,6.9s2.5,6.8,5.6,8.6s9,3.1,9,3.1"
      />
      <path
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M235.5,53.7
		c0,0,0.7,1.1,0.9,3.5"
      />
      <path
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M248.9,55.1
		c0,0,0.1,1.8,1.3,3.1"
      />
      <path
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M256.7,60.2
		c0,0,0.4,1.4,0,2.7"
      />
      <path
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M197.9,69.3
		c0,0-4.4,4.1-4.2,7.6c0.1,3.5,1,4.1,1,4.1"
      />
      <path
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M194.8,87.3
		c0,0-0.7,2.5,0,4.1"
      />
      <path
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M206,91.4
		c0,0,1.3,4.7,2.7,6.5c1.4,1.8,3.4,3,5.1,4"
      />
      <path
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M232.2,91.4
		c0,0-0.6,3.8,0.6,6.1c1.1,2.3,0,3.5,0,3.5"
      />
      <path
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M215.7,94.7
		c0,0,2.5,6.1,7,8.5"
      />
      <path
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M221.7,96.2
		c0,0,2.8,5.4,5.5,5.7"
      />
      <path
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M226.6,96.1
		c0,0,2.4,5.1,4.3,5"
      />
      <path
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M255.2,165.1
		c-0.1,1-0.8,16.6,1.8,22.1c1.3,2.8-10.1,7-15,8.1c-1.2,0.3-2.4-0.4-2.9-1.5c-0.8-1.9-2.4-4.3-3.1-5.5c-2.8-5.1-10.1-3.9-22.6,4
		c0,0-5.3,3.3-6,0.2c-0.7-3.1,0.7-5.1-1.2-5.7c-1.8-0.6-4.8-3-2.1-2.8c0,0,6.7,1.4,10.3-0.5c6.1-3.2,14.9-6.3,16.8-8.6
		c3-3.4,3-7.3,1.9-8c-1.7-1-3.3,2.5-7.3,2.5c-4,0-5,0-8.7,0.8c-5.3,1.1-8.8,3.2-9.4,2.2c-0.6-1,0.1-5.7-0.6-5.6
		c-0.7,0.1-1.7-0.3-3.3,0.4c-1.7,0.7-5.6,1.7-5,0c0.6-1.9,1.8-4,1.3-5.1c-0.3-0.7-0.1-3.2,1.6-3.2c1.5-0.1,3.2-1.3,3.5-3.7
		c0.3-2.1-1.7-3.9-1.4-5.6c0.2-1.4-1.2-3-1.2-5c0-2-0.8-7-1.4-10.4c-0.6-3.5-1.2-8-1.3-13.2s1.3-10,3.4-13c2.1-3,4.3-6.1,4.7-7.1
		c0.4-0.9,2.6-0.8,3.5,0c0.9,0.8,2.5,1.6,4,1.6c1.5,0,2.6-0.8,3.9-0.8s3.3,2.4,4.7,1.9s3.5-2.1,4.9-2.2c1.4-0.1,4.4-0.9,6,0.6
		c1.6,1.5,9.7,7.2,9.5,15.6c-0.2,8.5-4.8,16.1-2.8,22.3c1.1,3.6,3.7,5.8,4.1,4.6c0.5-1.2,0.6-5.6,2.6-5.8c2.1-0.2,3.3,1.2,3.4,5
		s-0.8,8.2-0.3,8.8c0.5,0.7,2,1,1.8,3.9c-0.2,2.8-0.6,6.2,0.1,7.1C254.3,164.2,255.2,165.1,255.2,165.1"
      />
      <path
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M233.9,169.2
		c-2.1,0.2-5.7,2.7-7.3,3.2s-4.1,1.2-5.4,1.6c-1.2,0.4-2.8,0.3-5,0.6s-5.3,2.1-6.2,2.2c-0.9,0.1-0.5-4.4-0.5-4.4"
      />

      <line
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="214.3"
        y1="175.2"
        x2="214.3"
        y2="170.9"
      />

      <line
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="218.3"
        y1="169.9"
        x2="218.3"
        y2="174.3"
      />

      <line
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="223.4"
        y1="169.4"
        x2="223.4"
        y2="173.4"
      />

      <line
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="227.8"
        y1="169.2"
        x2="227.8"
        y2="171.7"
      />

      <line
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="231"
        y1="167.4"
        x2="231"
        y2="169.9"
      />
      <path
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M211.7,151.5
		c0,0-0.2-2.6,1.4-4.9c1.7-2.4,9.5-6.2,9-4c-0.7,3.3-3.3,6.5-5.9,7.6C214.1,151,213.2,152.1,211.7,151.5z"
      />
      <path
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M214.8,148.8
		c0,0-0.2,1.6,1.1,1.3c0.7-0.1,0.7-1.3,0.7-1.3"
      />
      <path
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M234.6,162.1
		c0,0,0.7-0.8,1.5-1c1.6-0.4,2.3,2,2,4.2"
      />
      <path
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M235.2,164.8
		c-0.5,1-0.7,5.3-2.1,7.4"
      />
      <path
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M208.6,148.9
		c0,0-2-1.8-2.8-2.1c-0.8-0.4-2.3,0.1-2.3,0.1"
      />
      <path
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M204.5,151.3
		c0,0,0.8,0.6,1.7,0.6c0.8,0,1.3-1.6-0.5-2.2"
      />
      <path
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M207.6,166.6
		l9.1-0.4c0,0,2.9-2.3,1.6-5s-3.7-2.9-6-2.8s-3.6,1.1-3.6,1.1"
      />
      <path d="M209.9,162.2c0-1,4.2-1.3,4.7-0.7c0.5,0.6,1.5,1.9-0.7,1.9C211.8,163.4,209.9,163.2,209.9,162.2z" />
      <path
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M249.2,143
		c-1.3-0.7-0.7,5.6-0.6,6.7c0.2,2.6-0.1,3.5-1.3,3.5"
      />
      <path
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M210.8,143.4
		c0.8-0.4,1.5-0.8,2.1-1.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.5,0.3-1,0.6-1.4c0.5-0.5,1.3-0.5,1.9-0.9c0.7-0.5,0.9-1.4,1.3-2.1
		c0.2-0.4,0.5-0.6,0.8-0.9c0.8-0.8,1.6-1.6,2.4-2.4c0.3-0.3,0.6-0.6,0.9-0.7c0.5-0.2,1.1-0.2,1.6-0.4c1.1-0.5,1.6-1.9,2.6-2.4
		c0.5-0.3,1.1-0.3,1.6-0.6c0.8-0.5,1.2-1.6,2.1-2c0.3-0.1,0.6-0.1,0.9-0.3c0.9-0.3,1.4-1.4,2.3-1.3"
      />
      <path
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M207.5,142
		c-0.5,0.2-1-0.2-1.2-0.7c-0.2-0.5-0.3-1.1-0.7-1.4c-0.3-0.3-0.8-0.3-1-0.7c-0.1-0.2-0.1-0.4-0.3-0.6c-0.1-0.2-0.3-0.3-0.5-0.3
		c-0.9-0.3-1.7-0.6-2.6-0.8"
      />
      <path
        d="M203.2,184.3c0,0-0.3-1.3,1.2-1.9c1.4-0.6,2.2-1.8,4.3-2.3c2.1-0.5,2.7-0.2,2.6-1.5s-1.1-1.9-1.1-1.9
		c0.9-0.1,4-1.8,6.2-2.2s3.8-0.3,5-0.6c1.2-0.4,3.8-1,5.4-1.6s5.1-3,7.3-3.2l0,0c-0.1,1.6-0.9,3.7-2.6,5.6c-2,2.3-10.7,5.3-16.8,8.6
		C210.9,185.3,203.2,184.3,203.2,184.3"
      />
      <path
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M223.9,210
		c0,0-0.4,4.1-0.3,5.5c0.1,1.4,0.3,6.4,0.3,6.4"
      />

      <line
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="268.7"
        y1="209"
        x2="269"
        y2="221.5"
      />
      <path
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M208.5,146.6
		c0,0,0-0.6,0.1,1.7c0.1,2.3-0.6,3.7-1.1,4.9c-0.5,1.2-1.1,4.5-1.6,4.9c-2,1.3-2.3,2.5-2.3,2.5s-1.1,1.8-2.5,2.1"
      />
    </g>
    <g id="chortle2">
      <path
        display="inline"
        stroke="#000000"
        strokeMiterlimit="10"
        d="M257.1,187.2c0,0,8.5,5.3,10.6,9.7c2.3,4.8,2.1,12.5,2.1,12.5
		l-8,0.4l0.3,12.1l-33.9,0.2l0.6-11.7h-5.9c0,0,0.6-2,0.6-4.6c0-5.9,12.8-15.6,13.8-16.3C238.2,188.7,257.1,187.2,257.1,187.2z"
      />
      <path
        display="inline"
        stroke="#000000"
        strokeMiterlimit="10"
        d="M121.5,187c0,0,2.4,2.9,4.8,3.7c2.4,0.8,9.6,5.7,9.6,11.4
		s0,7.8,0,7.8h-5.6V222H88.4v-8.3h-7.6c0,0,0,0,0.4-7.1c0.4-7.1,6.3-9.8,10.6-12.4c2.6-1.6,10.4-6.3,10.4-6.3"
      />
      <path
        display="inline"
        d="M210,187.2c0,0,11.2-8.5,13-10c2-1.7,2.9-4.9,2.9-4.9l8.7-3.6l0,0c-0.1,1.6-0.5,5.5-2.2,7.4
		c-2,2.3-8.3,6-14.4,9.2c-3.6,1.9-8.7,2.4-8.7,2.4"
      />
      <path
        display="inline"
        d="M140.7,124.7c0,0,4.5-3.7,4.9-8c0.4-4.4,1.5-25.4,0.1-30.7c-1.4-5.3-5-7.4-5-11.7c0,0,1.3-4.4,0-6.8
		c-1.3-2.4-0.4-11.2-4.1-11.2s-4.4,4.5-6.5,1.7s-4-8-8.3-10s-10.7-2.4-13-4c-2.3-1.6-17.2-9.8-18.2,0s0,13.9-1.1,15.3
		s-3.4,3.1-3.8,6.1c-0.4,3-2.7,5.1-3.5,8.2c-0.8,3.1-1.1,9.7-1.3,18.8s-1,15.5,0.6,21.5s2.5,9.7,3.2,12"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M103.1,192.7c0,0-2.3-12.4-4-17.9S92,155,90.6,148.3c-1.7-8.5-3.6-14.9-3.6-14.9s-3.8-3-3.2-5.8c0.6-2.8,3.2-3.8,3.2-3.8
		s-1.7-13.6-1.7-16.8c0-3.2-1-9.3,0-9.2c3.5,0.4,11.9-13.3,15.5-10.9c2.5,1.7,5.7,7.4,11,7.2s11.1-6.4,12-8c0.9-1.5,4.3-2.4,6.2-1.1
		s2.4-2.3,5.5-1.1c3,1.1,5.8,6.4,5.6,14.3c-0.2,7.9-0.6,26.5-0.6,26.5s2.1-0.9,2.1,2.1c0,3-0.8,4.9-2.1,5.3s-1.3,0.4-1.3,0.4
		s-2.8,9.2-6.7,19.2c-3.8,9.5-9.6,22.2-10.1,25.7s-1.6,15-1.6,15s-7.5,1-10.2,0.8C108.1,193.1,103.1,192.7,103.1,192.7z"
      />
      <path
        display="inline"
        stroke="#000000"
        strokeMiterlimit="10"
        d="M102.4,154c-0.7,1.3,3.5,0.1,5.6,0.8s2.9,0.7,6.2,0.7
		s3.8-0.5,7.5-0.8c3.7-0.3,4.1-0.9,3.5-1.8s-4.7-5.9-10.7-5.9S103.1,152.7,102.4,154z"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M122.5,150.1c-1.9-1.5-4.7-3.1-8-3.1c-3.1,0-6,1.9-8.2,3.5L122.5,150.1z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M106,165.6c0,0,0.9,1.8,5.1,1.8c4.2,0,8.1,0,8.1,0"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M97.3,149.4c0,0-0.6,1-0.8,2.8c-0.2,1.8,0.1,2.2,0.1,2.2"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M127.7,149.4c0,0,1,0.9,1.1,2.3c0.1,1.4,0,3.2,0,3.2"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M118,117.6c0,0,0.3,3.5,0.3,5.2s0.8,3.7,0.8,3.7"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M140.7,127.2c0,0-0.8,3.5,0,2.8"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M86.9,126.7c0,0,1.4,5.2,0,3.5"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M103.7,111.2c0,0,1.6,0.1,3.4,0.9c1.8,0.8,2.8,1.7,3.1,2.7"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M131,112.5c0,0-3.8-0.6-5.7,1.5c-1.6,1.7-1.2,4-1.2,4"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M109.5,128.2c0,0-3.3-1-5,1.5c-0.9,1.4-0.6,4.5-0.6,4.5"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M122.7,128.6c1.7-1.5,5.8,0.7,3.4,4.4"
      />
      <path
        display="inline"
        d="M105.6,135c-0.5-0.1,0.2-2.9,2.3-3.1s2.8,0.8,2.6,2.3c-0.2,1.4-0.5-1.4-2.3-0.7
		C107.1,134.5,106.8,135,105.6,135z"
      />
      <path
        display="inline"
        d="M125.6,134.1c0.5-1.1-0.1-2.3-2-2.4c-1.9-0.2-2.3,0.6-2.7,2.1c-0.3,1.3,0.7-1.3,2.3-0.6
		C124.3,134,124.6,134.1,125.6,134.1z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M110.5,134.1c0,0-0.1,5.8,1.6,7.8c0.6,0.7,3.7,1.8,5.6-2c1.9-3.8,3.5-6.3,3.5-6.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M127.6,117.3c1.7-1,3.2-1.3,2.5-1.9c-0.7-0.6-2.7-1.8-3.7-0.3C124.8,117.6,125.3,118.8,127.6,117.3z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M109.1,120c-1,0-0.4-0.4-2.3-1s-3.5-1.6-2.8-2.2s2.8-1.1,4.2,0C110,118.1,110.2,120,109.1,120z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M109,157.4c1,0.1,1,0.7,4.3,0.8c3.3,0.1,3.8-0.7,5.2-0.9"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M111.2,149.1c0,0,0.2-0.3,3-0.7"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M81.8,213.7c0,0-0.6,7,0,8.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M134.9,209.8c0,0,0.4,3.5,0,6.1c-0.4,2.6-0.4,6.1-0.4,6.1"
      />
      <g display="inline">
        <polygon
          fill="#FFFFFF"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
			124,152.2 103.8,152.6 106,150.6 122,150.1 		"
        />

        <line
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="116.4"
          y1="150.4"
          x2="117.4"
          y2="152.3"
        />

        <line
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="120.3"
          y1="150.4"
          x2="121.3"
          y2="152.3"
        />

        <line
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="108.9"
          y1="150.6"
          x2="107.6"
          y2="152.3"
        />

        <line
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="113.2"
          y1="150.5"
          x2="112.3"
          y2="152.3"
        />
      </g>

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M255.2,165.1c0,0,1.7,0.4,2.3-1.6c0.6-2,9.3-34.2,9.6-35.9c0.3-1.7,1.3-10,1.6-11.3c0.3-1.3,2-5.1,1.8-7.1c-0.1-2-0.6-5.4-0.3-6.8
		c0.3-1.4,1.3-4.2,0.8-8c-0.4-3.8-1.7-6.4-1.4-8s0.6-8.9-0.6-11.6c-1.1-2.7-3.5-6.1-4.2-6.1s-0.1-3.2-2.4-5.5
		c-2.3-2.3-5.8-3.1-5.8-3.1s-1.3-3.8-3.7-4.9s-4.1-0.1-4.1-0.1s-4.8-5.5-8.2-4.9s-5.2,3.5-5.2,3.5s-3.1-0.6-4.8-1.1
		c-1.7-0.6-4.4,0-4.4,0s-5.2-3.4-7.9-2.8s-5.6,4.2-5.6,4.2s-4.2-0.3-6.9,2.3c-2.7,2.5-3.2,6.1-3.2,6.1s-4.4-0.1-6.5,3
		c-2.1,3.1-2.1,6.5-2.1,6.5l-0.3,5.1c0,0-3.2,1.3-3.1,5.2s2,6.9,2.8,6.9s2.5,6.8,5.6,8.6s9,3.1,9,3.1"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M235.5,53.7c0,0,0.7,1.1,0.9,3.5"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M248.9,55.1c0,0,0.1,1.8,1.3,3.1"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M256.7,60.2c0,0,0.4,1.4,0,2.7"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M197.9,69.3c0,0-4.4,4.1-4.2,7.6c0.1,3.5,1,4.1,1,4.1"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M194.8,87.3c0,0-0.7,2.5,0,4.1"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M206,91.4c0,0,1.3,4.7,2.7,6.5c1.4,1.8,3.4,3,5.1,4"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M232.2,91.4c0,0-0.6,3.8,0.6,6.1c1.1,2.3,0,3.5,0,3.5"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M215.7,94.7c0,0,2.5,6.1,7,8.5"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M221.7,96.2c0,0,2.8,5.4,5.5,5.7"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M226.6,96.1c0,0,2.4,5.1,4.3,5"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M255.2,165.1c-0.1,1-0.8,16.6,1.8,22.1c1.3,2.8-10.1,7-15,8.1c-1.2,0.3-2.4-0.4-2.9-1.5c-0.8-1.9-2-3.9-3.2-4.6
		c-2.5-1.4-11.9,0.8-18.5,5c0,0-5.7,4-6.4,0.9c-0.7-3.1,0.1-3.3-1.1-4.1c-1.6-1.1-2.2-3.8,0.5-3.6c0,0,4-0.1,7.6-2
		c6.1-3.2,12.4-6.9,14.4-9.2c3-3.4,2.7-9.1,1.6-9.8c-1.7-1-3.3,2.5-7.3,2.5s-5,0-8.7,0.8c-5.3,1.1-8.8,3.2-9.4,2.2
		c-0.6-1,0-6.2-0.7-6.1c-0.7,0.1-1.7-0.3-3.3,0.4c-1.7,0.7-5.6,1.7-5,0c0.6-1.9,1.8-4,1.3-5.1c-0.3-0.7-0.1-3.2,1.6-3.2
		c1.5-0.1,2.6-0.3,2.9-2.7c0.3-2.1-1.7-3.9-1.4-5.6c0.2-1.4-1.2-3-1.2-5c0-2,0-7.2-0.6-10.6c-0.6-3.5-1.2-8-1.3-13.2
		s0.5-9.8,2.5-12.8c2.1-3,4.3-6.1,4.7-7.1c0.4-0.9,2.6-0.8,3.5,0c0.9,0.8,2.5,1.6,4,1.6c1.5,0,2.6-0.8,3.9-0.8s3.3,2.4,4.7,1.9
		s3.5-2.1,4.9-2.2c1.4-0.1,4.4-0.9,6,0.6c1.6,1.5,9.7,7.2,9.5,15.6c-0.2,8.5-4.8,16.1-2.8,22.3c1.1,3.6,3.7,5.8,4.1,4.6
		c0.5-1.2,0.6-5.6,2.6-5.8c2.1-0.2,3.3,1.2,3.4,5s-0.8,8.2-0.3,8.8c0.5,0.7,2,1,1.8,3.9c-0.2,2.8-0.6,6.2,0.1,7.1
		C254.3,164.2,255.2,165.1,255.2,165.1"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M234.7,168.7c-2.1,0.2-5.7,2.7-7.3,3.2c-1.6,0.5-4.9,1.1-6.2,1.5c-1.2,0.4-2,0.4-4.2,0.7c-2.2,0.4-5.3,2.1-6.2,2.2
		s-0.5-4.4-0.5-4.4"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="215"
        y1="174.7"
        x2="215"
        y2="170.4"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="219"
        y1="169.4"
        x2="219"
        y2="173.8"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="224.1"
        y1="168.9"
        x2="224.1"
        y2="172.6"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="228.5"
        y1="168.7"
        x2="228.5"
        y2="171.2"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="231"
        y1="167.4"
        x2="231"
        y2="169.9"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M211.7,150.5c0,0-0.2-2.6,1.4-4.9c1.7-2.4,9.5-6.2,9-4c-0.7,3.3-3.3,6.5-5.9,7.6C214.1,150.1,213.2,151.2,211.7,150.5z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M214.8,147.9c0,0-0.2,1.6,1.1,1.3c0.7-0.1,0.7-1.3,0.7-1.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M235.1,161.1c0,0,0.7-0.7,1.5-0.9c1.6-0.3,2.3,1.8,2,3.7"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M234.6,164.5c-0.5,1,1.3,4.5-1.5,7.6"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M208.8,147.6c0,0-2-1.8-2.8-2.1c-0.8-0.4-2.3,0.1-2.3,0.1"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M204.7,150c0,0,0.8,0.6,1.7,0.6c0.8,0,1.3-1.6-0.5-2.2"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M208.2,165.6l9.1-0.4c0,0,2.9-2.3,1.6-5c-1.3-2.8-3.7-2.9-6-2.8c-2.3,0.1-3.6,1.1-3.6,1.1"
      />
      <path
        display="inline"
        d="M210.5,161.2c0-1,4.2-1.3,4.7-0.7c0.5,0.6,1.5,1.9-0.7,1.9C212.4,162.4,210.5,162.2,210.5,161.2z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M249.6,143c-1.6-0.6-0.9,5.3-0.8,6.4c0.3,2.5-0.2,3.3-1.6,3.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M211.1,142.6c0.8-0.4,1.5-0.8,2.1-1.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.5,0.3-1,0.6-1.4c0.5-0.5,1.3-0.5,1.9-0.9
		c0.7-0.5,0.9-1.4,1.3-2.1c0.2-0.4,0.5-0.6,0.8-0.9c0.8-0.8,1.6-1.6,2.4-2.4c0.3-0.3,0.6-0.6,0.9-0.7c0.5-0.2,1.1-0.2,1.6-0.4
		c1.1-0.5,1.6-1.9,2.6-2.4c0.5-0.3,1.1-0.3,1.6-0.6c0.8-0.5,1.2-1.6,2.1-2c0.3-0.1,0.6-0.1,0.9-0.3c0.9-0.3,1.4-1.4,2.3-1.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M207.7,141.6c-0.5,0.2-1-0.2-1.2-0.7c-0.2-0.5-0.3-1.1-0.7-1.4c-0.3-0.3-0.8-0.3-1-0.7c-0.1-0.2-0.1-0.4-0.3-0.6
		c-0.1-0.2-0.3-0.3-0.5-0.3c-0.9-0.3-1.7-0.6-2.6-0.8"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M223.9,210c0,0-0.4,4.1-0.3,5.5c0.1,1.4,0.3,6.4,0.3,6.4"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="268.7"
        y1="209"
        x2="269"
        y2="221.5"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M208.5,146.6c0,0,0-0.6,0.1,1.7c0.1,2.3-0.6,3.7-1.1,4.9c-0.5,1.2-0.5,3.5-1,3.9c-2,1.3-2.3,2.5-2.3,2.5s-1.1,1.8-2.5,2.1"
      />
    </g>
    <g id="chortle3">
      <path
        display="inline"
        stroke="#000000"
        strokeMiterlimit="10"
        d="M121.5,187c0,0,2.4,2.9,4.8,3.7c2.4,0.8,9.6,5.7,9.6,11.4
		s0,7.8,0,7.8h-5.6V222H88.4v-8.3h-7.6c0,0,0,0,0.4-7.1c0.4-7.1,6.3-9.8,10.6-12.4c2.6-1.6,10.4-6.3,10.4-6.3"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M234.7,168.7c-2.1,0.2-5.7,2.7-7.3,3.2c-1.6,0.5-4.9,1.1-6.2,1.5c-1.2,0.4-2,0.4-4.2,0.7c-2.2,0.4-5.3,2.1-6.2,2.2
		s-0.5-4.4-0.5-4.4"
      />
      <path
        display="inline"
        stroke="#000000"
        strokeMiterlimit="10"
        d="M256.5,186.3c0,0,12.8,1.4,13.6,9.2c0.5,5.5,0.1,14.1,0.1,14.1
		l-8.5,0.2l0.3,12.1l-33.9,0.2l0.6-11.7h-5.9c0,0,0.5-5.6,0.6-8.2c0.1-10.7,12.9-12,13.8-12.7C238.2,188.7,256.5,186.3,256.5,186.3z
		"
      />
      <path
        display="inline"
        d="M140.7,124.7c0,0,4.5-3.7,4.9-8c0.4-4.4,1.5-25.4,0.1-30.7c-1.4-5.3-5-7.4-5-11.7c0,0,1.3-4.4,0-6.8
		c-1.3-2.4-0.4-11.2-4.1-11.2s-4.4,4.5-6.5,1.7s-4-8-8.3-10s-10.7-2.4-13-4c-2.3-1.6-17.2-9.8-18.2,0s0,13.9-1.1,15.3
		s-3.4,3.1-3.8,6.1c-0.4,3-2.7,5.1-3.5,8.2c-0.8,3.1-1.1,9.7-1.3,18.8s-1,15.5,0.6,21.5s2.5,9.7,3.2,12"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M103.1,192.7c0,0-2.3-12.4-4-17.9S92,155,90.6,148.3c-1.7-8.5-3.6-14.9-3.6-14.9s-3.8-3-3.2-5.8c0.6-2.8,3.2-3.8,3.2-3.8
		s-1.7-13.6-1.7-16.8c0-3.2-1-9.3,0-9.2c3.5,0.4,11.9-13.3,15.5-10.9c2.5,1.7,5.7,7.4,11,7.2s11.1-6.4,12-8c0.9-1.5,4.3-2.4,6.2-1.1
		s2.4-2.3,5.5-1.1c3,1.1,5.8,6.4,5.6,14.3c-0.2,7.9-0.6,26.5-0.6,26.5s2.1-0.9,2.1,2.1c0,3-0.8,4.9-2.1,5.3s-1.3,0.4-1.3,0.4
		s-2.8,9.2-6.7,19.2c-3.8,9.5-9.6,22.2-10.1,25.7s-1.6,15-1.6,15s-7.5,1-10.2,0.8C108.1,193.1,103.1,192.7,103.1,192.7z"
      />
      <path
        display="inline"
        stroke="#000000"
        strokeMiterlimit="10"
        d="M101.1,153.7c-2.3,0.2,2.3,2.5,7,3.1c2.2,0.3,2.9,0.8,6.2,0.8
		s4.1-0.3,7.7-1.2c4-1,5.9-1.4,4.9-2c-5.1-3-7-7.2-12.3-7C108.5,147.8,105.6,153.3,101.1,153.7z"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M123.5,152c-3.6-3.1-5.6-4.7-9-4.5c-3.1,0-6.8,2.9-9,4.4L123.5,152z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M104.7,166.5c0,0,0.9,2.3,5.2,2.3s4.2,0.1,6.1,0s3.5-1.4,3.5-1.4"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M97.3,147.3c0,0-1.3,2.6-1.4,4.9c0,1.8,0.1,2.2,0.1,2.2"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M127.4,148.6c0,0,0.8,0.9,1.1,2.3c0.3,1.5,0.4,3,0.4,3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M141,126.9c0,0-0.8,3.5,0,2.8"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M109,159.7c1,0.1,1,0.7,4.3,0.8c3.3,0.1,3.8-0.7,5.2-0.9"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M111.4,149.8c0,0,1.1-0.7,2.6-0.8"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M81.8,213.7c0,0-0.6,7,0,8.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M134.9,209.8c0,0,0.4,3.5,0,6.1c-0.4,2.6-0.4,6.1-0.4,6.1"
      />

      <polygon
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
		125.9,154.1 101.1,154.1 106,152.1 123.2,152.1 	"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="116.4"
        y1="152.5"
        x2="117"
        y2="153.9"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="120.3"
        y1="152.5"
        x2="120.9"
        y2="153.7"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="109.1"
        y1="152"
        x2="107.9"
        y2="153.9"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="113.2"
        y1="152.6"
        x2="112.5"
        y2="153.9"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M255.2,165.1c0,0,1.4,0.3,2.3-1.6c1.6-3.5,9.3-33,9.6-34.7c0.3-1.7,1.3-11.2,1.6-12.4c0.3-1.3,2-5.1,1.8-7.1
		c-0.1-2-0.6-5.4-0.3-6.8c0.3-1.4,1.3-4.2,0.8-8c-0.4-3.8-1.7-6.4-1.4-8s1.4-10,0.3-12.7c-1.1-2.7-3.7-5.8-4.4-5.8
		c-0.7,0,0-3.5-2.3-5.7c-2.3-2.3-5.8-3.1-5.8-3.1s-1.3-3.8-3.7-4.9c-2.4-1.1-4.1-0.1-4.1-0.1s-3-3.3-7.5-3.6
		c-5.2-0.3-5.9,2.2-5.9,2.2s-2.2-1.5-3.9-2.1c-1.7-0.6-5.3,0.9-5.3,0.9s-1.8-3.7-7.9-2.8c-3.5,0.5-5.2,4.9-5.2,4.9s-5.8-1.2-8.5,1.3
		c-2.7,2.5-2.6,6.4-2.6,6.4s-4.7,0.8-6.8,4c-2.1,3.1-1.4,10-1.4,10s-2.8,1.8-2.7,5.8s1,6.5,1.8,6.5c0.8,0,2.1,8.1,5.2,10
		s10.2,3.1,10.2,3.1"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M236.3,52.6c0,0,0.7,1.1,0.9,3.5"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M249.7,54.1c0,0,0.1,1.8,1.3,3.1"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M257.5,59.1c0,0,0.4,1.4,0,2.7"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M198.3,69.1c0,0-3,1.4-2.8,7.2c0.1,3.5-0.4,4.5-0.4,4.5"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M194.7,85.2c0,0-0.7,2.5,0,4.1"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M206,91.4c0,0,3.4,6.2,7.4,9"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M232.9,91c0,0-0.6,3.8,0.6,6.1s0,3.5,0,3.5"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M215.4,93.7c0,0,3.4,6.3,7.8,8.8"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M222.6,95.8c0,0,2.8,5.4,5.5,5.7"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M227.7,95.8c0,0,2.4,5.1,4.3,5"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M255.2,165.1c-0.1,1-0.8,16.6,1.8,22.1c1.3,2.8-10.4,6.2-15.3,7.2c-1.2,0.3-2.1-1-2.5-2.1c-0.8-1.9-4.1-6.1-5.4-6.6
		c-2.7-1.1-10.9-1.8-17.9,4.4c0,0-7.3,3.5-8.2,0.4c-0.8-2.8-0.4-3.6-1.6-4.4c-1.6-1.1-2.2-3.8,0.5-3.6c0,0,4-0.1,7.6-2
		c6.1-3.2,16.2-2,18.2-4.3c3-3.4,2.7-9.1,1.6-9.8c-1.7-1-3.3,2.5-7.3,2.5s-5,0-8.7,0.8c-5.3,1.1-8.8,3.2-9.4,2.2
		c-0.6-1,0-6.2-0.7-6.1c-0.7,0.1-1.7-0.3-3.3,0.4c-1.7,0.7-5.5,1.1-4.9-0.6c0.6-1.9,1.7-3.4,1.2-4.5c-0.3-0.7-0.1-3.2,1.6-3.2
		c1.5-0.1,2.6-0.3,2.9-2.7c0.3-2.1-1-3.8-0.7-5.5c0.2-1.4-1.2-3-1.2-5c0-2-0.7-7.3-1.3-10.8c-0.6-3.5-1.2-8-1.3-13.2
		s0.5-9.8,2.5-12.8c2.1-3,5.4-6.1,5.8-7.1c0.4-0.9,3.3-1,4.2-0.3c0.9,0.8,0.6,1.4,2.1,1.4c1.5,0,2.7-0.3,4-0.3s4.8,1.7,6.2,1.3
		s2.4-1.5,3.8-1.6c1.4-0.1,5.2-0.9,6.8,0.6c1.6,1.5,8.5,7.2,8.3,15.6c-0.2,8.5-4.2,15.5-2.3,21.6c1.1,3.6,2.8,5.5,3.2,4.2
		c0.5-1.2,0.9-4.6,3-4.8c2.1-0.2,3.3,1.2,3.4,5s-0.8,8.2-0.3,8.8c0.5,0.7,2,1,1.8,3.9c-0.2,2.8-0.6,6.2,0.1,7.1
		C254.3,164.2,255.2,165.1,255.2,165.1"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="215"
        y1="174.7"
        x2="215"
        y2="170.4"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="219"
        y1="169.4"
        x2="219"
        y2="173.8"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="224.1"
        y1="168.9"
        x2="224.1"
        y2="172.6"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="228.5"
        y1="168.7"
        x2="228.5"
        y2="171.2"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="231"
        y1="167.4"
        x2="231"
        y2="169.9"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M212.5,150c0,0-1-2,0.7-4.4c1.7-2.4,9.4-5.6,8.9-3.4c-0.7,3.3-3.2,5.8-5.8,7C214.1,150.1,214,150.7,212.5,150z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M214.9,147.5c0,0-0.2,1.6,1.1,1.3c0.7-0.1,0.7-1.3,0.7-1.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M235.1,161.1c0,0,1.1-0.7,1.9-0.6c1.6,0.1,2.9,1.1,2.7,3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M234.6,164.5c-0.5,1,1.3,4.5-1.5,7.6"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M208.5,146.5c0,0-1.1-0.5-1.8-0.8c-0.8-0.4-2.3,0.1-2.3,0.1"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M205.4,150.1c0,0,0.8,0.6,1.7,0.6c0.8,0,1.3-1.6-0.5-2.2"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M208.6,165.5l9.1-0.4c0,0,2.9-2.3,1.6-5c-1.3-2.8-3.7-2.9-6-2.8c-2.3,0.1-3.6,1.1-3.6,1.1"
      />
      <path
        display="inline"
        d="M210.5,161.2c0-1,4.2-1.3,4.7-0.7c0.5,0.6,1.5,1.9-0.7,1.9C212.4,162.4,210.5,162.2,210.5,161.2z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M249.6,143c-1.6-0.6-1.2,3.6,0,5.3c0.8,1.3,0.4,4.7-2.3,4.4"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M211.1,142.6c0.8-0.4,1.5-0.8,2.1-1.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.5,0.3-1,0.6-1.4c0.5-0.5,1.3-0.5,1.9-0.9
		c0.7-0.5,0.9-1.4,1.3-2.1c0.2-0.4,0.5-0.6,0.8-0.9c0.8-0.8,1.6-1.6,2.4-2.4c0.3-0.3,0.6-0.6,0.9-0.7c0.5-0.2,1.1-0.2,1.6-0.4
		c1.1-0.5,1.6-1.9,2.6-2.4c0.5-0.3,1.1-0.3,1.6-0.6c0.8-0.5,1.2-1.6,2.1-2c0.3-0.1,0.6-0.1,0.9-0.3c0.9-0.3,1.4-1.4,2.3-1.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M207.7,141.6c-0.5,0.2-1-0.2-1.2-0.7c-0.2-0.5-0.3-1.1-0.7-1.4c-0.3-0.3-0.8-0.3-1-0.7c-0.1-0.2-0.1-0.4-0.3-0.6
		c-0.1-0.2-0.3-0.3-0.5-0.3c-0.9-0.3-1.7-0.6-2.6-0.8"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M223.9,210c0,0-0.4,4.1-0.3,5.5c0.1,1.4,0.3,6.4,0.3,6.4"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="268.7"
        y1="209"
        x2="269"
        y2="221.5"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M208.5,146.6c0,0,0-0.6,0.1,1.7c0.1,2.3-0.6,3.7-1.1,4.9c-0.5,1.2-0.5,3.5-1,3.9c-2,1.3-2.3,2.5-2.3,2.5s-1.1,1.8-2.5,2.1"
      />
      <path
        display="inline"
        d="M204.8,183.3c-1.8-1.1,3.6-3.6,5.4-5.2c6.5-6.2,15.7-5.8,15.7-5.8l8.7-3.6l0,0c-0.1,1.6-0.5,5.5-2.2,7.4
		c-2,2.3-12.1,1.1-18.2,4.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M131,112.5c0,0-3.8-0.6-5.7,1.5c-1.6,1.7-1.2,3.9-1.2,3.9"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M127.6,117.2c1.7-1,3.2-1.3,2.5-1.8c-0.7-0.6-2.7-1.7-3.7-0.3C124.8,117.5,125.3,118.6,127.6,117.2z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M118,117.5c0,0,0.3,3.4,0.3,5.1s0.8,3.6,0.8,3.6"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M86.9,126.7c0,0,1.4,5.2,0,3.5"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M103.7,111.2c0,0,1.6,0.1,3.4,0.9c1.8,0.8,2.8,1.7,3.1,2.7"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M109.5,127.9c0,0-3.3-1-5,1.4c-0.9,1.3-0.6,4.4-0.6,4.4"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M122.7,128.3c1.7-1.4,5.8,0.6,3.4,4.3"
      />
      <path
        display="inline"
        d="M105.6,134.5c-0.5-0.1,0.2-2.9,2.3-3c2.1-0.2,2.8,0.8,2.6,2.2c-0.2,1.4-0.5-1.4-2.3-0.6
		C107.1,134.1,106.8,134.5,105.6,134.5z"
      />
      <path
        display="inline"
        d="M125.6,133.6c0.5-1.1-0.1-2.2-2-2.4c-1.9-0.2-2.3,0.6-2.7,2c-0.3,1.2,0.7-1.3,2.3-0.6
		C124.3,133.6,124.6,133.6,125.6,133.6z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M110.5,133.7c0,0-0.1,5.7,1.6,7.7c0.6,0.7,3.7,1.8,5.6-1.9c1.9-3.7,3.5-6.2,3.5-6.2"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M109.1,119.9c-1,0-0.4-0.4-2.3-1c-1.9-0.6-3.5-1.6-2.8-2.1s2.8-1,4.2,0C110,117.9,110.2,119.9,109.1,119.9z"
      />
    </g>
    <g id="chortle4">
      <path
        display="inline"
        stroke="#000000"
        strokeMiterlimit="10"
        d="M256.5,186.3c0,0,12.8,2.6,13.6,10.4c0.5,5.5,0.1,12.9,0.1,12.9
		l-8.5,0.2l0.3,12.1l-33.9,0.2l0.6-11.7h-5.9c0,0,0.8-5.7,0.6-8.2c-1.1-12.8,12.9-12,13.8-12.7C238.2,188.7,256.5,186.3,256.5,186.3
		z"
      />
      <path
        display="inline"
        stroke="#000000"
        strokeMiterlimit="10"
        d="M121.4,188.2c0,0,2.8,2.7,4.9,4.1c2.6,1.9,9.8,5.6,9.8,11.3
		s-0.2,6.2-0.2,6.2h-5.6V222H88.4v-8.3h-7.6c0,0,0.1,0.6,0.1-6.5c0-5.8,7.4-11.9,11.7-14.5c2.6-1.6,9.6-4.9,9.6-4.9"
      />
      <path
        display="inline"
        d="M140.7,124.7c0,0,4.5-3.7,4.9-8c0.4-4.4,1.5-25.4,0.1-30.7c-1.4-5.3-5-7.4-5-11.7c0,0,1.3-4.4,0-6.8
		c-1.3-2.4-0.4-11.2-4.1-11.2s-4.4,4.5-6.5,1.7s-4-8-8.3-10s-10.7-2.4-13-4c-2.3-1.6-17.2-9.8-18.2,0s0,13.9-1.1,15.3
		s-3.4,3.1-3.8,6.1c-0.4,3-2.7,5.1-3.5,8.2c-0.8,3.1-1.1,9.7-1.3,18.8s-1,15.5,0.6,21.5s2.5,9.7,3.2,12"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M103.1,192.7c0,0-2.3-12.4-4-17.9S92,155,90.6,148.3c-1.7-8.5-3.6-14.9-3.6-14.9s-3.8-3-3.2-5.8c0.6-2.8,3.2-3.8,3.2-3.8
		s-1.7-13.6-1.7-16.8c0-3.2-1-9.3,0-9.2c3.5,0.4,11.9-13.3,15.5-10.9c2.5,1.7,5.7,7.4,11,7.2s11.1-6.4,12-8c0.9-1.5,4.3-2.4,6.2-1.1
		s2.4-2.3,5.5-1.1c3,1.1,5.8,6.4,5.6,14.3c-0.2,7.9-0.6,26.5-0.6,26.5s2.1-0.9,2.1,2.1c0,3-0.8,4.9-2.1,5.3s-1.3,0.4-1.3,0.4
		s-2.8,9.2-6.7,19.2c-3.8,9.5-9.6,22.2-10.1,25.7s-1.6,15-1.6,15s-7.5,1-10.2,0.8C108.1,193.1,103.1,192.7,103.1,192.7z"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M234.7,168.7c-2.1,0.2-5.7,2.7-7.3,3.2c-1.6,0.5-4.9,1.1-6.2,1.5c-1.2,0.4-2,0.4-4.2,0.7c-2.2,0.4-5.3,2.1-6.2,2.2
		s-0.5-4.4-0.5-4.4"
      />
      <path
        display="inline"
        stroke="#000000"
        strokeMiterlimit="10"
        d="M101.9,157.8c-1.7,1.5,1.1,0.8,5.9,1.1c1.5,0.1,2.1,2.4,5.3,2.5
		c4.5,0.1,4.6-1.9,8.3-2.9c1.6-0.4,3.8,0.3,3.3-0.7c-2.1-4.2-4.5-9.9-10.2-9.4C108,148.9,105.3,154.9,101.9,157.8z"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M122.6,153.7c-2.3-3.7-3.5-5.5-8.2-5.2c-4.4,0-6.1,3.3-8.3,5.2L122.6,153.7z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M105.2,171.7c0,0,0.7,2.1,4.3,2.1s3.4,0.1,5.1,0c1.6-0.1,2.9-1.3,2.9-1.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M99.1,148.3c0,0-1.7,3.2-1.8,6c-0.1,2.2,0.2,2.6,0.2,2.6"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M126.3,149.6c0,0,1,1.1,1.3,2.9c0.3,1.8,0.5,3.8,0.4,3.8"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M141,126.9c0,0-0.8,3.5,0,2.8"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M109,162.5c1,0.1,1,0.8,4.3,0.9c3.3,0.1,3.8-0.8,5.2-1"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M111.4,151.1c0,0,1.1-0.8,2.6-0.9"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M81.8,213.7c0,0-0.6,7,0,8.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M134.9,209.8c0,0,0.4,3.5,0,6.1c-0.4,2.6-0.4,6.1-0.4,6.1"
      />

      <polygon
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
		123.6,155.9 103.8,156 106.5,152.9 122,152.8 	"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="116.7"
        y1="153.2"
        x2="116.9"
        y2="155.6"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="120.3"
        y1="153.4"
        x2="120.7"
        y2="155.5"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="109.1"
        y1="153"
        x2="107.9"
        y2="155.9"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="113.3"
        y1="153"
        x2="112.5"
        y2="155.7"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M255.2,165.1c0,0,1.4,0.3,2.3-1.6c1.6-3.5,9.3-33,9.6-34.7c0.3-1.7,1.3-11.2,1.6-12.4c0.3-1.3,2-5.1,1.8-7.1
		c-0.1-2-0.6-5.4-0.3-6.8c0.3-1.4,1.3-4.2,0.8-8c-0.4-3.8-1.7-6.4-1.4-8s1.4-10,0.3-12.7c-1.1-2.7-3.7-5.8-4.4-5.8
		c-0.7,0,0-3.5-2.3-5.7c-2.3-2.3-5.8-3.1-5.8-3.1s-1.3-3.8-3.7-4.9c-2.4-1.1-4.1-0.1-4.1-0.1s-3-3.3-7.5-3.6
		c-5.2-0.3-5.9,2.2-5.9,2.2s-2.2-1.5-3.9-2.1c-1.7-0.6-5.3,0.9-5.3,0.9s-1.8-3.7-7.9-2.8c-3.5,0.5-5.2,4.9-5.2,4.9s-5.8-1.2-8.5,1.3
		c-2.7,2.5-2.6,6.4-2.6,6.4s-4.7,0.8-6.8,4c-2.1,3.1-1.4,10-1.4,10s-2.8,1.8-2.7,5.8s1,6.5,1.8,6.5c0.8,0,2.1,8.1,5.2,10
		s10.2,3.1,10.2,3.1"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M236.3,52.6c0,0,0.7,1.1,0.9,3.5"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M249.7,54.1c0,0,0.1,1.8,1.3,3.1"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M257.5,59.1c0,0,0.4,1.4,0,2.7"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M198.3,69.1c0,0-3,1.4-2.8,7.2c0.1,3.5-0.4,4.5-0.4,4.5"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M194.7,85.2c0,0-0.7,2.5,0,4.1"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M206,91.4c0,0,3.4,6.2,7.4,9"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M232.9,91c0,0-0.6,3.8,0.6,6.1s0,3.5,0,3.5"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M215.4,93.7c0,0,3.4,6.3,7.8,8.8"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M222.6,95.8c0,0,2.8,5.4,5.5,5.7"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M227.7,95.8c0,0,2.4,5.1,4.3,5"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M255.2,165.1c-0.1,1-0.8,16.6,1.8,22.1c1.3,2.8-10.4,6.2-15.3,7.2c-1.2,0.3-2.1-1-2.5-2.1c-0.8-1.9-1-2-2.3-2.5
		c-2.7-1.1-12.7-0.7-19.8,4.4c0,0-4.4,5.1-5.3,2.1c-0.8-2.8-0.8-4.4-2.1-5.3c-1.6-1.1-1.4-4.1,1.3-4c0,0,3.4,0.8,7-1.5
		c5.8-3.7,11.9-6.9,13.9-9.2c3-3.4,3.1-9.3,1.9-10c-1.7-1-3,1.2-6.8,1.9c-4,0.8-5.2,0.6-8.8,1.3c-5.3,1.1-9.1,3.3-9.6,2.3
		c-0.6-1,0-5.9-0.7-5.8c-0.7,0.1-1.7-0.3-3.3,0.4c-1.7,0.7-5.5,1.1-4.9-0.6c0.6-1.9,1.7-3.4,1.2-4.5c-0.3-0.7-0.1-3.2,1.6-3.2
		c1.5-0.1,2.6-0.3,2.9-2.7c0.3-2.1-1-4.1-0.7-5.9c0.2-1.4-1.7-1.5-2.1-3.1c-0.7-2.9,0.1-9.2-0.4-12.6c-0.6-3.5-1.2-8-1.3-13.2
		s0.5-9.8,2.5-12.8c2.1-3,5.4-6.1,5.8-7.1c0.4-0.9,3.3-1,4.2-0.3c0.9,0.8,0.6,1.4,2.1,1.4c1.5,0,2.7-0.3,4-0.3s4.8,1.7,6.2,1.3
		s2.4-1.5,3.8-1.6c1.4-0.1,5.2-0.9,6.8,0.6c1.6,1.5,8.5,7.2,8.3,15.6c-0.2,8.5-4.2,15.5-2.3,21.6c1.1,3.6,2.8,5.5,3.2,4.2
		c0.5-1.2,0.9-4.6,3-4.8c2.1-0.2,3.3,1.2,3.4,5s-0.8,8.2-0.3,8.8c0.5,0.7,2,1,1.8,3.9c-0.2,2.8-0.6,6.2,0.1,7.1
		C254.3,164.2,255.2,165.1,255.2,165.1"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="215"
        y1="174.7"
        x2="215"
        y2="170.4"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="219"
        y1="169.4"
        x2="219"
        y2="173.8"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="224.1"
        y1="168.9"
        x2="224.1"
        y2="172.6"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="228.5"
        y1="168"
        x2="228.5"
        y2="171.2"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="231"
        y1="167.4"
        x2="231"
        y2="169.9"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M212.5,150c0,0-1-2,0.7-4.4c1.7-2.4,9.4-5.6,8.9-3.4c-0.7,3.3-3.2,5.8-5.8,7C214.1,150.1,214,150.7,212.5,150z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M214.2,146.4c0,0-0.2,1.6,1.1,1.3c0.7-0.1,0.7-1.3,0.7-1.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M234.2,160.9c0,0,1.1-0.7,1.9-0.6c1.6,0.1,2.9,1.1,2.7,3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M234.6,164.5c-0.5,1,0.7,4.6-0.7,8.2"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M208.5,146.5c0,0-1.1-0.5-1.8-0.8c-0.8-0.4-3.8-1.1-3.8-1.1"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M204.7,148.7c0,0,0.3,0.4,1.2,0.4c0.8,0,1.3-1.6-0.5-2.2"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M208.6,165.8l8.7-0.4c0,0,2.8-2.3,1.6-5c-1.2-2.8-3.6-2.9-5.8-2.8c-2.2,0.1-3.5,1.1-3.5,1.1"
      />
      <path
        display="inline"
        d="M210.5,161.6c0-1,4.2-1.3,4.7-0.7c0.5,0.6,1.5,1.9-0.7,1.9C212.4,162.7,210.5,162.6,210.5,161.6z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M249.6,143c-1.6-0.6-1.2,3.6,0,5.3c0.8,1.3,0.4,4.7-2.3,4.4"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M211.1,142.6c0.8-0.4,1.5-0.8,2.1-1.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.5,0.3-1,0.6-1.4c0.5-0.5,1.3-0.5,1.9-0.9
		c0.7-0.5,0.9-1.4,1.3-2.1c0.2-0.4,0.5-0.6,0.8-0.9c0.8-0.8,1.6-1.6,2.4-2.4c0.3-0.3,0.6-0.6,0.9-0.7c0.5-0.2,1.1-0.2,1.6-0.4
		c1.1-0.5,1.6-1.9,2.6-2.4c0.5-0.3,1.1-0.3,1.6-0.6c0.8-0.5,1.2-1.6,2.1-2c0.3-0.1,0.6-0.1,0.9-0.3c0.9-0.3,1.4-1.4,2.3-1.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M207.7,141.6c-0.5,0.2-1-0.2-1.2-0.7c-0.2-0.5-0.3-1.1-0.7-1.4c-0.3-0.3-0.8-0.3-1-0.7c-0.1-0.2-0.1-0.4-0.3-0.6
		c-0.1-0.2-0.3-0.3-0.5-0.3c-0.9-0.3-1.7-0.6-2.6-0.8"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M223.9,210c0,0-0.4,4.1-0.3,5.5c0.1,1.4,0.3,6.4,0.3,6.4"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="268.7"
        y1="209"
        x2="269"
        y2="221.5"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M208.5,146.6c0,0,0-0.6,0.1,1.7c0.1,2.3-0.6,3.7-1.1,4.9c-0.5,1.2-0.5,3.9-1,4.2c-2,1.3-2.3,2.5-2.3,2.5s-1.1,1.8-2.5,2.1"
      />
      <path
        display="inline"
        d="M209.1,188c-1.2-0.8,3.6-4.4,5.8-5.3c6.3-2.6,12.8-10.9,12.8-10.9l6.9-3.1l0,0c-0.1,1.6-0.7,5.8-2.4,7.8
		c-2,2.3-8,5.8-14.1,9"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M131,112.6c0,0-3.8-0.6-5.7,1.6c-1.6,1.8-1.2,4.2-1.2,4.2"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M127.6,117.7c1.7-1.1,3.2-1.4,2.5-2c-0.7-0.6-2.7-1.9-3.7-0.3C124.8,118,125.3,119.2,127.6,117.7z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M118,117.6c0,0,0.3,3.5,0.3,5.2s0.8,3.7,0.8,3.7"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M86.9,126.7c0,0,1.4,5.2,0,3.5"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M103.6,111.5c0,0,1.6,0.1,3.4,1c1.8,0.9,2.8,1.7,3.1,2.8"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M109.5,128.4c0,0-3.3-1-5,1.5c-0.9,1.4-0.6,4.6-0.6,4.6"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M122.7,128.8c1.7-1.5,5.8,0.7,3.4,4.5"
      />
      <path
        display="inline"
        d="M105.6,135.2c-0.5-0.1,0.2-2.9,2.3-3.1c2.1-0.2,2.8,0.9,2.6,2.3c-0.2,1.4-0.5-1.4-2.3-0.7
		C107.1,134.7,106.8,135.2,105.6,135.2z"
      />
      <path
        display="inline"
        d="M125.6,134.3c0.5-1.1-0.1-2.3-2-2.5s-2.3,0.6-2.7,2.1c-0.3,1.3,0.7-1.3,2.3-0.6
		C124.3,134.2,124.6,134.3,125.6,134.3z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M110.5,134.3c0,0-0.1,5.9,1.6,7.9c0.6,0.7,3.7,1.8,5.6-2s3.5-6.4,3.5-6.4"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M109.1,120.5c-1,0-0.4-0.4-2.3-1.2s-3.5-1.8-2.8-2.4c0.7-0.6,2.8-1.2,4.2,0C110,118.3,110.2,120.5,109.1,120.5z"
      />
    </g>
    <g id="chortle5">
      <path
        display="inline"
        stroke="#000000"
        strokeMiterlimit="10"
        d="M256.5,186.3c0,0,12.8,2.6,13.6,10.4c0.5,5.5,0.1,12.9,0.1,12.9
		l-8.5,0.2l0.3,12.1l-33.9,0.2l0.6-11.7h-5.9c0,0,0.8-5.7,0.6-8.2c-1.1-12.8,12.9-12,13.8-12.7C238.2,188.7,256.5,186.3,256.5,186.3
		z"
      />
      <path
        display="inline"
        stroke="#000000"
        strokeMiterlimit="10"
        d="M121.4,188.2c0,0,2.8,2.7,4.9,4.1c2.6,1.9,9.8,5.6,9.8,11.3
		s-0.2,6.2-0.2,6.2h-5.6V222H88.4v-8.3h-7.6c0,0,0.1,0.6,0.1-6.5c0-5.8,7.4-11.9,11.7-14.5c2.6-1.6,9.6-4.9,9.6-4.9"
      />
      <path
        display="inline"
        d="M140.7,124.7c0,0,4.5-3.7,4.9-8c0.4-4.4,1.5-25.4,0.1-30.7c-1.4-5.3-5-7.4-5-11.7c0,0,1.3-4.4,0-6.8
		c-1.3-2.4-0.4-11.2-4.1-11.2s-4.4,4.5-6.5,1.7s-4-8-8.3-10s-10.7-2.4-13-4c-2.3-1.6-17.2-9.8-18.2,0s0,13.9-1.1,15.3
		s-3.4,3.1-3.8,6.1c-0.4,3-2.7,5.1-3.5,8.2c-0.8,3.1-1.1,9.7-1.3,18.8s-1,15.5,0.6,21.5s2.5,9.7,3.2,12"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M103.1,192.7c0,0-2.3-12.4-4-17.9S92,155,90.6,148.3c-1.7-8.5-3.6-14.9-3.6-14.9s-3.8-3-3.2-5.8c0.6-2.8,3.2-3.8,3.2-3.8
		s-1.7-13.6-1.7-16.8c0-3.2-1-9.3,0-9.2c3.5,0.4,11.9-13.3,15.5-10.9c2.5,1.7,5.7,7.4,11,7.2s11.1-6.4,12-8c0.9-1.5,4.3-2.4,6.2-1.1
		s2.4-2.3,5.5-1.1c3,1.1,5.8,6.4,5.6,14.3c-0.2,7.9-0.6,26.5-0.6,26.5s2.1-0.9,2.1,2.1c0,3-0.8,4.9-2.1,5.3s-1.3,0.4-1.3,0.4
		s-2.8,9.2-6.7,19.2c-3.8,9.5-9.6,22.2-10.1,25.7s-1.6,15-1.6,15s-7.5,1-10.2,0.8C108.1,193.1,103.1,192.7,103.1,192.7z"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M234.7,168.7c-2.1,0.2-5.7,2.7-7.3,3.2c-1.6,0.5-4.9,1.1-6.2,1.5c-1.2,0.4-2,0.4-4.2,0.7c-2.2,0.4-5.3,2.1-6.2,2.2
		s-0.5-4.4-0.5-4.4"
      />
      <path
        display="inline"
        stroke="#000000"
        strokeMiterlimit="10"
        d="M101.9,157.8c-1.7,1.5,1.1,0.8,5.9,1.1c1.5,0.1,2.1,2.4,5.3,2.5
		c4.5,0.1,4.6-1.9,8.3-2.9c1.6-0.4,3.8,0.3,3.3-0.7c-2.1-4.2-4.5-9.9-10.2-9.4C108,148.9,105.3,154.9,101.9,157.8z"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M122.6,153.7c-2.3-3.7-3.5-5.5-8.2-5.2c-4.4,0-6.1,3.3-8.3,5.2L122.6,153.7z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M105.2,171.7c0,0,0.7,2.1,4.3,2.1s3.4,0.1,5.1,0c1.6-0.1,2.9-1.3,2.9-1.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M99.1,148.3c0,0-1.7,3.2-1.8,6c-0.1,2.2,0.2,2.6,0.2,2.6"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M126.3,149.6c0,0,1,1.1,1.3,2.9c0.3,1.8,0.5,3.8,0.4,3.8"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M141,126.9c0,0-0.8,3.5,0,2.8"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M109,162.5c1,0.1,1,0.8,4.3,0.9c3.3,0.1,3.8-0.8,5.2-1"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M111.4,151.1c0,0,1.1-0.8,2.6-0.9"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M81.8,213.7c0,0-0.6,7,0,8.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M134.9,209.8c0,0,0.4,3.5,0,6.1c-0.4,2.6-0.4,6.1-0.4,6.1"
      />

      <polygon
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
		123.6,155.9 103.8,156 106.5,152.9 122,152.8 	"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="116.7"
        y1="153.2"
        x2="116.9"
        y2="155.6"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="120.3"
        y1="153.4"
        x2="120.7"
        y2="155.5"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="109.1"
        y1="153"
        x2="107.9"
        y2="155.9"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="113.3"
        y1="153"
        x2="112.5"
        y2="155.7"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M255.2,165.1c0,0,1.4,0.3,2.3-1.6c1.6-3.5,9.3-33,9.6-34.7c0.3-1.7,1.3-11.2,1.6-12.4c0.3-1.3,2-5.1,1.8-7.1
		c-0.1-2-0.6-5.4-0.3-6.8c0.3-1.4,1.3-4.2,0.8-8c-0.4-3.8-1.7-6.4-1.4-8s1.4-10,0.3-12.7c-1.1-2.7-3.7-5.8-4.4-5.8
		c-0.7,0,0-3.5-2.3-5.7c-2.3-2.3-5.8-3.1-5.8-3.1s-1.3-3.8-3.7-4.9c-2.4-1.1-4.1-0.1-4.1-0.1s-3-3.3-7.5-3.6
		c-5.2-0.3-5.9,2.2-5.9,2.2s-2.2-1.5-3.9-2.1c-1.7-0.6-5.3,0.9-5.3,0.9s-1.8-3.7-7.9-2.8c-3.5,0.5-5.2,4.9-5.2,4.9s-5.8-1.2-8.5,1.3
		c-2.7,2.5-2.6,6.4-2.6,6.4s-4.7,0.8-6.8,4c-2.1,3.1-1.4,10-1.4,10s-2.8,1.8-2.7,5.8s1,6.5,1.8,6.5c0.8,0,2.1,8.1,5.2,10
		s10.2,3.1,10.2,3.1"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M236.3,52.6c0,0,0.7,1.1,0.9,3.5"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M249.7,54.1c0,0,0.1,1.8,1.3,3.1"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M257.5,59.1c0,0,0.4,1.4,0,2.7"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M198.3,69.1c0,0-3,1.4-2.8,7.2c0.1,3.5-0.4,4.5-0.4,4.5"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M194.7,85.2c0,0-0.7,2.5,0,4.1"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M206,91.4c0,0,3.4,6.2,7.4,9"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M232.9,91c0,0-0.6,3.8,0.6,6.1s0,3.5,0,3.5"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M215.4,93.7c0,0,3.4,6.3,7.8,8.8"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M222.6,95.8c0,0,2.8,5.4,5.5,5.7"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M227.7,95.8c0,0,2.4,5.1,4.3,5"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M255.2,165.1c-0.1,1-0.8,16.6,1.8,22.1c1.3,2.8-10.4,6.2-15.3,7.2c-1.2,0.3-2.1-1-2.5-2.1c-0.8-1.9-1-2-2.3-2.5
		c-2.7-1.1-12.7-0.7-19.8,4.4c0,0-4.4,5.1-5.3,2.1c-0.8-2.8-0.8-4.4-2.1-5.3c-1.6-1.1-1.4-4.1,1.3-4c0,0,3.4,0.8,7-1.5
		c5.8-3.7,11.9-6.9,13.9-9.2c3-3.4,3.1-9.3,1.9-10c-1.7-1-3,1.2-6.8,1.9c-4,0.8-5.2,0.6-8.8,1.3c-5.3,1.1-9.1,3.3-9.6,2.3
		c-0.6-1,0-5.9-0.7-5.8c-0.7,0.1-1.7-0.3-3.3,0.4c-1.7,0.7-5.5,1.1-4.9-0.6c0.6-1.9,1.7-3.4,1.2-4.5c-0.3-0.7-0.1-3.2,1.6-3.2
		c1.5-0.1,2.6-0.3,2.9-2.7c0.3-2.1-1-4.1-0.7-5.9c0.2-1.4-1.7-1.5-2.1-3.1c-0.7-2.9,0.1-9.2-0.4-12.6c-0.6-3.5-1.2-8-1.3-13.2
		s0.5-9.8,2.5-12.8c2.1-3,5.4-6.1,5.8-7.1c0.4-0.9,3.3-1,4.2-0.3c0.9,0.8,0.6,1.4,2.1,1.4c1.5,0,2.7-0.3,4-0.3s4.8,1.7,6.2,1.3
		s2.4-1.5,3.8-1.6c1.4-0.1,5.2-0.9,6.8,0.6c1.6,1.5,8.5,7.2,8.3,15.6c-0.2,8.5-4.2,15.5-2.3,21.6c1.1,3.6,2.8,5.5,3.2,4.2
		c0.5-1.2,0.9-4.6,3-4.8c2.1-0.2,3.3,1.2,3.4,5s-0.8,8.2-0.3,8.8c0.5,0.7,2,1,1.8,3.9c-0.2,2.8-0.6,6.2,0.1,7.1
		C254.3,164.2,255.2,165.1,255.2,165.1"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="215"
        y1="174.7"
        x2="215"
        y2="170.4"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="219"
        y1="169.4"
        x2="219"
        y2="173.8"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="224.1"
        y1="168.9"
        x2="224.1"
        y2="172.6"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="228.5"
        y1="168"
        x2="228.5"
        y2="171.2"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="231"
        y1="167.4"
        x2="231"
        y2="169.9"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M212.5,150c0,0-1-2,0.7-4.4c1.7-2.4,9.4-5.6,8.9-3.4c-0.7,3.3-3.2,5.8-5.8,7C214.1,150.1,214,150.7,212.5,150z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M214.2,146.4c0,0-0.2,1.6,1.1,1.3c0.7-0.1,0.7-1.3,0.7-1.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M234.2,160.9c0,0,1.1-0.7,1.9-0.6c1.6,0.1,2.9,1.1,2.7,3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M234.6,164.5c-0.5,1,0.7,4.6-0.7,8.2"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M208.5,146.5c0,0-1.1-0.5-1.8-0.8c-0.8-0.4-3.8-1.1-3.8-1.1"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M204.7,148.7c0,0,0.3,0.4,1.2,0.4c0.8,0,1.3-1.6-0.5-2.2"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M208.6,165.8l8.7-0.4c0,0,2.8-2.3,1.6-5c-1.2-2.8-3.6-2.9-5.8-2.8c-2.2,0.1-3.5,1.1-3.5,1.1"
      />
      <path
        display="inline"
        d="M210.5,161.6c0-1,4.2-1.3,4.7-0.7c0.5,0.6,1.5,1.9-0.7,1.9C212.4,162.7,210.5,162.6,210.5,161.6z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M249.6,143c-1.6-0.6-1.2,3.6,0,5.3c0.8,1.3,0.4,4.7-2.3,4.4"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M211.1,142.6c0.8-0.4,1.5-0.8,2.1-1.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.5,0.3-1,0.6-1.4c0.5-0.5,1.3-0.5,1.9-0.9
		c0.7-0.5,0.9-1.4,1.3-2.1c0.2-0.4,0.5-0.6,0.8-0.9c0.8-0.8,1.6-1.6,2.4-2.4c0.3-0.3,0.6-0.6,0.9-0.7c0.5-0.2,1.1-0.2,1.6-0.4
		c1.1-0.5,1.6-1.9,2.6-2.4c0.5-0.3,1.1-0.3,1.6-0.6c0.8-0.5,1.2-1.6,2.1-2c0.3-0.1,0.6-0.1,0.9-0.3c0.9-0.3,1.4-1.4,2.3-1.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M207.7,141.6c-0.5,0.2-1-0.2-1.2-0.7c-0.2-0.5-0.3-1.1-0.7-1.4c-0.3-0.3-0.8-0.3-1-0.7c-0.1-0.2-0.1-0.4-0.3-0.6
		c-0.1-0.2-0.3-0.3-0.5-0.3c-0.9-0.3-1.7-0.6-2.6-0.8"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M223.9,210c0,0-0.4,4.1-0.3,5.5c0.1,1.4,0.3,6.4,0.3,6.4"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="268.7"
        y1="209"
        x2="269"
        y2="221.5"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M208.5,146.6c0,0,0-0.6,0.1,1.7c0.1,2.3-0.6,3.7-1.1,4.9c-0.5,1.2-0.5,3.9-1,4.2c-2,1.3-2.3,2.5-2.3,2.5s-1.1,1.8-2.5,2.1"
      />
      <path
        display="inline"
        d="M209.1,188c-1.2-0.8,3.6-4.4,5.8-5.3c6.3-2.6,12.8-10.9,12.8-10.9l6.9-3.1l0,0c-0.1,1.6-0.7,5.8-2.4,7.8
		c-2,2.3-8,5.8-14.1,9"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M131,112.6c0,0-3.8-0.6-5.7,1.6c-1.6,1.8-1.2,4.2-1.2,4.2"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M127.6,117.7c1.7-1.1,3.2-1.4,2.5-2c-0.7-0.6-2.7-1.9-3.7-0.3C124.8,118,125.3,119.2,127.6,117.7z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M118,117.6c0,0,0.3,3.5,0.3,5.2s0.8,3.7,0.8,3.7"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M86.9,126.7c0,0,1.4,5.2,0,3.5"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M103.6,111.5c0,0,1.6,0.1,3.4,1c1.8,0.9,2.8,1.7,3.1,2.8"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M109.5,128.4c0,0-3.3-1-5,1.5c-0.9,1.4-0.6,4.6-0.6,4.6"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M122.7,128.8c1.7-1.5,5.8,0.7,3.4,4.5"
      />
      <path
        display="inline"
        d="M105.6,135.2c-0.5-0.1,0.2-2.9,2.3-3.1c2.1-0.2,2.8,0.9,2.6,2.3c-0.2,1.4-0.5-1.4-2.3-0.7
		C107.1,134.7,106.8,135.2,105.6,135.2z"
      />
      <path
        display="inline"
        d="M125.6,134.3c0.5-1.1-0.1-2.3-2-2.5s-2.3,0.6-2.7,2.1c-0.3,1.3,0.7-1.3,2.3-0.6
		C124.3,134.2,124.6,134.3,125.6,134.3z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M110.5,134.3c0,0-0.1,5.9,1.6,7.9c0.6,0.7,3.7,1.8,5.6-2s3.5-6.4,3.5-6.4"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M109.1,120.5c-1,0-0.4-0.4-2.3-1.2s-3.5-1.8-2.8-2.4c0.7-0.6,2.8-1.2,4.2,0C110,118.3,110.2,120.5,109.1,120.5z"
      />
    </g>
    <g id="chortle6">
      <path
        display="inline"
        stroke="#000000"
        strokeMiterlimit="10"
        d="M120.8,187.4c0,0,3.6,2.5,5.7,4c2.6,1.9,9.5,3.3,9.5,8.9
		s-0.2,9.5-0.2,9.5h-5.6V222H88.4v-8.3h-7.6c0,0-0.5-1.3-0.6-8.5c-0.1-5,6.6-9.6,10.4-11.6c2.7-1.4,11.6-5.8,11.6-5.8"
      />
      <path
        display="inline"
        d="M140.7,124.7c0,0,4.5-3.7,4.9-8c0.4-4.4,1.3-24.9,0-30.2c-1.4-5.3-4.9-8-4.9-12.2c0,0,1.2-4.3,0-6.8
		c-2.1-4.2-0.4-11.2-4.1-11.2s-4,3-6.1,0.2c-2.1-2.8-4.4-6.5-8.8-8.5s-10.7-2.4-13-4c-2.3-1.6-17.2-9.8-18.2,0s0,13.9-1.1,15.3
		s-3.4,3.1-3.8,6.1c-0.4,3-0.9,4.4-1.8,7.5c-0.8,3.1-4.5,7.9-3,19.4c1.2,9-1.1,16,1.3,21.6c2.4,5.8,1.7,9.5,2.4,11.8"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M102.3,193.4c0,0-2.1-13.6-3.8-19.1c-1.7-5.5-7.9-20.3-9.2-27c-1.7-8.5-2.3-14-2.3-14s-3.8-3-3.2-5.8c0.6-2.8,3.2-3.8,3.2-3.8
		s-1.7-13.6-1.7-16.8c0-3.2-1-9.3,0-9.2c3.5,0.4,11.9-13.3,15.5-10.9c2.5,1.7,5.7,7.4,11,7.2s11.1-6.4,12-8c0.9-1.5,4.3-2.4,6.2-1.1
		s2.4-2.3,5.5-1.1c3,1.1,5.7,2.8,5.6,14.3c0,7.9-0.6,26.5-0.6,26.5s2.1-0.9,2.1,2.1c0,3-0.8,4.9-2.1,5.3s-1.3,0.4-1.3,0.4
		s-2.2,10-6.1,20c-3.8,9.5-10.2,21.7-10.8,25.2s-2.4,15.2-2.4,15.2s-6.6,0.5-9.3,0.3C108.1,193.1,102.3,193.4,102.3,193.4z"
      />
      <path
        display="inline"
        fill="#00000F"
        stroke="#000000"
        strokeMiterlimit="10"
        d="M101.4,153.9c-2.2,0.2,1.7,2.3,6.6,3
		c1.5,0.2,2.6,1.2,5.9,1.1c4.2-0.1,4.2-0.2,7.8-1.2c1.6-0.4,5.3-1.6,4.4-2.3c-3.7-2.8-6-6.9-11.4-6.6
		C108.3,148.4,106,153.5,101.4,153.9z"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M255.2,165.1c0,0,1.3,0.3,2.3-1.6c3.4-6.2,6.2-22.6,9.6-34.7c0.5-1.7,1.3-11.2,1.6-12.4c0.3-1.3,2-5.1,1.8-7.1
		c-0.1-2-0.6-5.4-0.3-6.8c0.3-1.4,1.3-4.2,0.8-8c-0.4-3.8-1.7-6.4-1.4-8s1.4-10,0.3-12.7c-1.1-2.7-3.7-5.8-4.4-5.8
		c-0.7,0,0-3.5-2.3-5.7c-2.3-2.3-5.8-3.1-5.8-3.1s-1.3-3.8-3.7-4.9c-2.4-1.1-4.1-0.1-4.1-0.1s-3-3.3-7.5-3.6
		c-5.2-0.3-5.9,2.2-5.9,2.2s-2.2-1.5-3.9-2.1c-1.7-0.6-5.3,0.9-5.3,0.9s-1.8-3.7-7.9-2.8c-3.5,0.5-5.2,4.9-5.2,4.9s-5.8-1.2-8.5,1.3
		c-2.7,2.5-2.6,6.4-2.6,6.4s-4.7,0.8-6.8,4c-2.1,3.1-1.4,10-1.4,10s-2.8,1.8-2.7,5.8s1,6.5,1.8,6.5c0.8,0,2.1,8.1,5.2,10
		s10.2,3.1,10.2,3.1"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M236.3,52.6c0,0,0.7,1.1,0.9,3.5"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M249.7,54.1c0,0,0.1,1.8,1.3,3.1"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M257.5,59.1c0,0,0.4,1.4,0,2.7"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M198.3,69.1c0,0-3,1.4-2.8,7.2c0.1,3.5-0.4,4.5-0.4,4.5"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M194.7,85.2c0,0-0.7,2.5,0,4.1"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M206,91.4c0,0,3.6,7.5,7.6,10.4"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M232.9,91c0,0-0.6,3.8,0.6,6.1s-0.2,4-0.2,4"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M215.4,93.7c0,0,2.8,7,7.2,9.4"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M222.6,95.8c0,0,2.8,5.4,5.5,5.7"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M227.7,95.8c0,0,2.3,5.3,4.2,5.2"
      />
      <path
        display="inline"
        stroke="#000000"
        strokeMiterlimit="10"
        d="M256.1,184.8c0,0,13,3.1,13.9,11.9c0.6,5.5,0.1,12.9,0.1,12.9
		l-8.5,0.2l0.3,12.1l-33.9,0.2l0.6-11.7h-5.9c0,0,0.7-5.7,0.6-8.2c-0.7-11.3,12.9-12,13.8-12.7C238.2,188.7,256.1,184.8,256.1,184.8
		z"
      />
      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M255.2,165.2c-0.1,1-0.9,16.6,1.8,22.1c1.3,2.8-10.1,7-15,8c-1.2,0.3-2.4-0.4-2.9-1.5c-0.8-1.9-1.8-4.3-2.5-5.5
		c-3.3-4.9-10.7-5.2-22.3,2.8c0,0-6.7,2.5-7.4-0.6c-0.7-3.1,0.7-4.6-1.1-5.2s-4.1-2.6-1.4-2.5c0,0,5.9-1.4,9.5-3.3
		c6.1-3.2,13.5-4,15.8-5.9c5.3-4.3,6.5-8.1,5.2-8.1c-1.4,0-4.6,2.7-8.6,2.7c-4,0-5.4,0.4-9.1,1.2c-5.3,1.1-8.9,3.2-9.4,2.2
		c-0.6-1,0.6-6.4,0-6.3c-0.7,0.1-2.1,0.9-3.7,1.6c-1.7,0.7-5.2,0.6-4.7-1.1c0.6-1.9,1.9-3.4,1.4-4.6c-0.3-0.7,0.5-3.7,2.1-3.8
		c1.5-0.1,2.5-0.1,2.8-2.4c0.3-2.1-1.6-4.1-1.3-5.8c0.2-1.4-1.2-3-1.2-5c0-2-1.2-6.9-1.8-10.4c-0.6-3.5-0.7-8-0.8-13.2
		c-0.1-5.2,0.9-10,2.9-13c2.1-3,4.3-6.1,4.7-7c0.4-0.9,2.6-0.7,3.5,0c0.9,0.8,2.5,1.6,4,1.6c1.5,0,2.6-0.7,3.9-0.7
		c1.2,0,3.3,2.4,4.7,1.9c1.4-0.5,3.5-2.1,4.9-2.2c1.4-0.1,4.4-0.9,6,0.6c1.6,1.5,9.7,7.2,9.5,15.7s-4.5,16.2-2.5,22.4
		c1.1,3.6,3.3,5.8,3.8,4.6c0.5-1.2,0.6-5.6,2.7-5.8c2.1-0.2,3.3,1.2,3.4,5c0.1,3.8-0.8,8.2-0.3,8.8s2,1,1.8,3.9
		c-0.2,2.8-0.6,6.2,0.1,7.1C254.2,164.2,255.2,165.2,255.2,165.2"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M123.8,152.8c-2.4-2.6-4.9-4.9-9.2-4.7c-5.4,0.6-7.3,3.4-9.8,4.8L123.8,152.8z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M104.5,166.7c0,0,0.9,2.1,5.4,2.1c4.5,0,4.3,0.1,6.4,0c2.1-0.1,3.6-1.3,3.6-1.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M97.7,146.7c0,0-1.7,3.2-1.8,6c-0.1,2.2,0.2,2.6,0.2,2.6"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M127.7,148.7c0,0,0.9,1.2,1,3c0.1,1.9,0,3.8,0,3.8"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M141,126.9c0,0-0.8,3.5,0,2.8"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M108.9,159.6c1.2,0.2,1.2,1.2,5.2,1.3c4,0.1,4.7-1.2,6.3-1.5"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M111.1,150.4c0,0,1.1-0.8,2.6-0.9"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M81.8,213.7c0,0-0.6,7,0,8.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M134.9,209.8c0,0,0.4,3.5,0,6.1c-0.4,2.6-0.4,6.1-0.4,6.1"
      />

      <polygon
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
		125.8,155.2 101.4,154.8 104.8,152.8 123.2,153 	"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="116.8"
        y1="153"
        x2="116.9"
        y2="154.8"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="120.3"
        y1="152.9"
        x2="120.7"
        y2="154.6"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="108.7"
        y1="153.1"
        x2="108.2"
        y2="154.7"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="113.1"
        y1="153.1"
        x2="112.7"
        y2="154.7"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M234.9,160.8c0,0,1.1-0.7,1.9-0.6c1.6,0.1,2.9,1.1,2.7,3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M249.6,143c-1.6-0.6-1.1,3.7,0,5.4c0.8,1.3,0.4,4.7-2.2,4.4"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M223.9,210c0,0-0.4,4.1-0.3,5.5c0.1,1.4,0.3,6.4,0.3,6.4"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="268.7"
        y1="209"
        x2="269"
        y2="221.5"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M209.4,146.6c0,0,0-0.6,0.1,1.7s-1.2,3.7-1.8,4.9c-0.5,1.2-0.5,3.9-1.1,4.2c-2.1,1.3-2.3,2.5-2.3,2.5s-1.2,1.8-2.6,2.1"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M86.9,126.7c0,0-1.2,2.5,0,3.5"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M212.1,150.2c0,0-0.2-2.6,1.4-4.9c1.7-2.4,9.5-6.2,9-4c-0.7,3.3-3.3,6.5-5.9,7.6C214.5,149.8,213.6,150.9,212.1,150.2z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M215.2,147.6c0,0-0.2,1.6,1.1,1.3c0.7-0.1,0.7-1.3,0.7-1.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M208.8,147.2c0,0-2-1.8-2.8-2.1s-1.8,0-1.8,0"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M204.9,149.4c0,0,0.4,0.3,1.2,0.3c0.8,0,1.3-1.6-0.5-2.2"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M207.9,165.4l10.2-0.8c0,0,2.2-1.6,0.9-4.4c-1.3-2.8-3.1-3.1-5.4-3c-2.3,0.1-3.4,1.5-3.4,1.5"
      />
      <path
        display="inline"
        d="M210.6,161.4c0-1,4.2-1.3,4.7-0.7c0.5,0.6,1.5,1.9-0.7,1.9C212.4,162.5,210.6,162.4,210.6,161.4z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M211.8,142c0.8-0.4,1.5-0.8,2.1-1.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.5,0.3-1,0.6-1.4c0.5-0.5,1.3-0.5,1.9-0.9c0.7-0.5,0.9-1.4,1.3-2.1
		c0.2-0.4,0.5-0.6,0.8-0.9c0.8-0.8,1.6-1.6,2.4-2.4c0.3-0.3,0.6-0.6,0.9-0.7c0.5-0.2,1.1-0.2,1.6-0.4c1.1-0.5,1.6-1.9,2.6-2.4
		c0.5-0.3,1.1-0.3,1.6-0.6c0.8-0.5,1.2-1.6,2.1-2c0.3-0.1,0.6-0.1,0.9-0.3c0.9-0.3,1.4-1.4,2.3-1.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M207.8,141.1c-0.5,0.2-1-0.2-1.2-0.7c-0.2-0.5-0.3-1.1-0.7-1.4c-0.3-0.3-0.8-0.3-1-0.7c-0.1-0.2-0.1-0.4-0.3-0.6
		c-0.1-0.2-0.3-0.3-0.5-0.3c-0.9-0.3-1.7-0.6-2.6-0.8"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M233.8,168.7c-2.1,0.2-5.5,3.2-7.1,3.7s-4.3,0.7-5.5,1.1s-2.7,0.8-4.8,1.1s-5.3,2.1-6.2,2.2c-0.9,0.1-0.7-4.9-0.7-4.9"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="214.3"
        y1="175.2"
        x2="214.1"
        y2="170.4"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="218.1"
        y1="169.4"
        x2="218.1"
        y2="173.9"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="223.2"
        y1="168.9"
        x2="223.2"
        y2="172.9"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="227.6"
        y1="168.7"
        x2="227.6"
        y2="171.2"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="230.8"
        y1="167.5"
        x2="230.8"
        y2="169.4"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M235.1,163.7c0.2,0.6,1.9,3.8-4.1,9"
      />
      <path
        display="inline"
        d="M203.3,183.5c0,0-1.6-4.1,1.8-5.3c5.1-1.7,9-3.2,11.2-3.6s3.6-0.7,4.8-1.1s3.9-0.6,5.5-1.1
		s4.8-2.3,7.1-3.7l0,0c-0.1,1.6-0.9,2.8-4.1,5c-3.2,2.3-9.7,2.7-15.8,5.9C210.3,181.6,203.3,183.5,203.3,183.5"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M118,117.6c0,0,1.3,3.8,1.3,5.5s0,4.1,0,4.1"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M104.1,111.9c0,0,1.6,0.1,3.4,0.9c1.8,0.8,2.8,1.7,3.1,2.7"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M131,113c0,0-3.8-0.6-5.7,1.5c-1.6,1.7-1.2,4-1.2,4"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M109.1,128.6c0,0-3.3-1-5,1.5c-0.9,1.4-0.6,4.5-0.6,4.5"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M122.4,129.3c1.7-1.5,5.8,0.7,3.4,4.4"
      />
      <path
        display="inline"
        d="M105.6,135.6c-0.5-0.1,0.2-2.9,2.3-3.1s2.8,0.8,2.6,2.3c-0.2,1.4-0.5-1.4-2.3-0.7
		C107.1,135.1,106.8,135.6,105.6,135.6z"
      />
      <path
        display="inline"
        d="M125.6,134.6c0.5-1.1-0.1-2.3-2-2.4c-1.9-0.2-2.3,0.6-2.7,2.1c-0.3,1.3,0.7-1.3,2.3-0.6
		C124.3,134.6,124.6,134.6,125.6,134.6z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M110.5,134.7c0,0-0.1,5.8,1.6,7.8c0.6,0.7,3.7,1.8,5.6-2c1.9-3.8,3.5-6.3,3.5-6.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M127.6,117.8c1.7-1,3.2-1.3,2.5-1.9c-0.7-0.6-2.7-1.8-3.7-0.3C124.8,118,125.3,119.2,127.6,117.8z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M109.1,120c-1,0-0.4-0.4-2.3-1s-3.5-1.6-2.8-2.2s2.8-1.1,4.2,0C110,118.1,110.2,120,109.1,120z"
      />
    </g>
    <g id="chortle7">
      <path
        display="inline"
        fill="#00000F"
        stroke="#000000"
        strokeMiterlimit="10"
        d="M120.8,187.4c0,0,3.9,2.7,6.2,3.8
		c3.2,1.6,9.1,1.8,9,7.3c-0.1,5.6-0.2,11.2-0.2,11.2h-5.6V222H88.4v-8.3h-7.6c0,0-0.1-2-0.1-9.2c0-9,5.5-10.4,10.4-12
		c2.9-0.9,11-4.7,11-4.7"
      />
      <path
        display="inline"
        d="M140.7,124.7c0,0,4.5-3.7,4.9-8c0.4-4.4,1.3-24.9,0-30.2c-1.4-5.3-4.9-8-4.9-12.2c0,0,1.2-4.3,0-6.8
		c-2.1-4.2-0.4-11.2-4.1-11.2s-4,3-6.1,0.2c-2.1-2.8-4.4-6.5-8.8-8.5s-10.7-2.4-13-4c-2.3-1.6-17.2-9.8-18.2,0s0,13.9-1.1,15.3
		s-3.4,3.1-3.8,6.1c-0.4,3-0.9,4.4-1.8,7.5c-0.8,3.1-4.5,7.9-3,19.4c1.2,9-1.1,16,1.3,21.6c2.4,5.8,1.7,9.5,2.4,11.8"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M102.3,193.4c0,0-2.1-13.6-3.8-19.1c-1.7-5.5-7.9-20.3-9.2-27c-1.7-8.5-2.3-14-2.3-14s-3.8-3-3.2-5.8c0.6-2.8,3.2-3.8,3.2-3.8
		s-1.7-13.6-1.7-16.8c0-3.2-1-9.3,0-9.2c3.5,0.4,11.9-13.3,15.5-10.9c2.5,1.7,5.7,7.4,11,7.2s11.1-6.4,12-8c0.9-1.5,4.3-2.4,6.2-1.1
		s2.4-2.3,5.5-1.1c3,1.1,5.7,2.8,5.6,14.3c0,7.9-0.6,26.5-0.6,26.5s2.1-0.9,2.1,2.1c0,3-0.8,4.9-2.1,5.3s-1.3,0.4-1.3,0.4
		s-2.2,10-6.1,20c-3.8,9.5-10.2,21.7-10.8,25.2s-2.4,15.2-2.4,15.2s-6.6,0.5-9.3,0.3C108.1,193.1,102.3,193.4,102.3,193.4z"
      />
      <path
        display="inline"
        fill="#00000F"
        stroke="#000000"
        strokeMiterlimit="10"
        d="M103.7,156.1c-2,1.1-0.7,1.9,4.3,2.3
		c1.5,0.1,1.3,1.8,6.4,1.8c4.9,0,3.7-0.7,7.3-1.9c1.6-0.5,4.4-0.9,3.5-2c-3.2-3.8-3.8-8.7-10.6-8.6
		C106,147.7,105.9,154.8,103.7,156.1z"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M255.2,165.1c0,0,1.3,0.3,2.3-1.6c3.4-6.2,6.2-22.6,9.6-34.7c0.5-1.7,1.3-11.2,1.6-12.4c0.3-1.3,2-5.1,1.8-7.1
		c-0.1-2-0.6-5.4-0.3-6.8c0.3-1.4,1.3-4.2,0.8-8c-0.4-3.8-2-6.6-1.7-8.3s0.7-9.5-0.4-12.1c-1.1-2.7-1.3-6.8-4.2-6.5
		c-0.7,0.1,0.1-3.1-2.1-5.4c-2.3-2.3-5.6-2.5-5.6-2.5s-0.8-3.2-3.2-4.4s-3.7-0.4-3.7-0.4s-3.2-4.1-7.8-4.4c-5.2-0.3-7.8,2-7.8,2
		s-0.7-0.4-2.4-1c-1.7-0.6-5.8,0.1-5.8,0.1s-2.8-3.3-8.9-2.4c-3.5,0.5-4,4-4,4s-5.8-0.3-8.5,2.3c-2.7,2.5-2,6.2-2,6.2s-5.9-0.1-8,3
		c-2.1,3.1-1,10.4-1,10.4s-3.7,2-3.5,5.9c0.1,4,2,6.6,2.8,6.6s1.1,8.8,5.4,11.7c3,2.1,10.6,1.4,10.6,1.4"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M234.5,52.5c0,0,2.5,1.3,2.8,3.7"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M249.7,54.1c0,0,0.1,1.8,1.3,3.1"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M257.5,59.1c0,0,0.4,1.4,0,2.7"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M198.3,69.1c0,0-3,1.4-2.8,7.2c0.1,3.5-0.4,4.5-0.4,4.5"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M194.7,85.2c0,0-0.7,2.5,0,4.1"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M205,91.4c0,0,3.6,7.5,7.6,10.4"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M232.9,91c0,0-0.6,3.8,0.6,6.1s-0.2,4-0.2,4"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M215.4,93.7c0,0,2.8,7,7.2,9.4"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M222.6,95.8c0,0,2.8,5.4,5.5,5.7"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M226.9,95.5c0,0,2.7,5.9,4.9,5.8"
      />
      <path
        display="inline"
        fill="#00000F"
        stroke="#000000"
        strokeMiterlimit="10"
        d="M256.1,187c0,0,13,0.9,13.9,9.6
		c0.6,5.5,0.1,12.9,0.1,12.9l-8.5,0.2l0.3,12.1l-33.9,0.2l0.6-11.7h-5.9c0,0,0.7-5.7,0.6-8.2c-0.7-11.3,12.9-12,13.8-12.7
		C238.2,188.7,256.1,187,256.1,187z"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M255.2,165.2c-0.1,1-0.9,16.6,1.8,22.1c1.3,2.8-10.1,7-15,8c-1.2,0.3-2.1-0.6-2.9-1.5c-1.3-1.7-1.9-2.8-2.9-4
		c-3.1-3.5-13.1,0.4-16.6,2.6c-3.2,2-7.1,6-8.3,3c-1-2.5-0.2-4.2-2-4.7s-1.8-3,0.9-2.8c0,0,3.6-1.1,7.2-3c6.1-3.2,11.6-5.9,13.9-7.9
		c5.3-4.3,4.3-12.2,3-12.2c-1.4,0-4.2,2.5-8.1,3.3c-3.9,0.8-5.3,0.6-9,1.4c-5.3,1.1-8.4,2.9-9,1.9c-0.6-1,0.2-6.4-0.5-6.3
		c-0.7,0.1-2.3,1.3-3.9,1.9c-1.7,0.7-5.3,0.2-4.7-1.5c0.6-1.9,2.2-3.1,1.7-4.2c-0.3-0.7,0.5-3.7,2.1-3.8c1.5-0.1,2.5-0.1,2.8-2.4
		c0.3-2.1-1.6-4.1-1.3-5.8c0.2-1.4-2.4-2.1-1.7-4.5c0.6-1.9-0.3-7.7-0.8-11.2c-0.6-3.5-1.1-7.7-1.2-12.9c-0.1-5.2,1.2-9.7,3.2-12.7
		c2.1-3,4-6.4,4.4-7.3c0.4-0.9,2.6-0.7,3.5,0c0.9,0.8,2.5,1.6,4,1.6c1.5,0,2.6-0.7,3.9-0.7c1.2,0,3.1,0.9,4.5,0.4
		c1.4-0.5,3.7-0.6,5.1-0.7c1.4-0.1,4.4-0.9,6,0.6c1.6,1.5,9.7,7.2,9.5,15.7s-4.5,16.2-2.5,22.4c1.1,3.6,3.3,5.8,3.8,4.6
		c0.5-1.2,0.6-5.6,2.7-5.8c2.1-0.2,3.3,1.2,3.4,5c0.1,3.8-0.8,8.2-0.3,8.8s2,1,1.8,3.9c-0.2,2.8-0.6,6.2,0.1,7.1
		C254.2,164.2,255.2,165.2,255.2,165.2"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M122.1,151.8c-2.4-3.7-4.2-4.2-7.7-4c-5.7,0-6.9,2.8-8.1,4.5L122.1,151.8z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M105.8,171.7c0,0,0.8,1.5,4.4,1.5s3.5,0.1,5.1,0c1.7-0.1,2.9-1.3,2.9-1.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M99.4,148c0,0-1.7,3.2-1.8,6c-0.1,2.2,0.2,2.6,0.2,2.6"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M127.4,149.5c0,0,0.6,1,0.8,2.7c0.1,1.9-0.2,3.9-0.3,3.9"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M141,126.9c0,0-0.8,3.5,0,2.8"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M109.4,161.2c1.1,0.2,1.1,1.1,4.8,1.2c3.7,0.1,4.3-1.1,5.8-1.4"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M111.1,150.5c0,0,1.1-1,2.6-1.1"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M81.8,213.7c0,0-0.6,7,0,8.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M134.9,209.8c0,0,0.4,3.5,0,6.1c-0.4,2.6-0.4,6.1-0.4,6.1"
      />

      <polygon
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
		124,154.9 104.9,154.9 106.5,152.1 121.8,151.7 	"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="116.6"
        y1="152.6"
        x2="116.7"
        y2="154.8"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="120.1"
        y1="152.5"
        x2="120.5"
        y2="154.6"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="108.5"
        y1="152.7"
        x2="108"
        y2="154.7"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="112.9"
        y1="152.7"
        x2="112.6"
        y2="154.7"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M234.2,160.5c0,0,1.1-0.7,1.9-0.6c1.6,0.1,2.9,1.1,2.7,3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M249.6,143c-1.6-0.6-1.1,3.7,0,5.4c0.8,1.3,0.4,4.7-2.2,4.4"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M223.9,210c0,0-0.4,4.1-0.3,5.5c0.1,1.4,0.3,6.4,0.3,6.4"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="268.7"
        y1="209"
        x2="269"
        y2="221.5"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M86.9,126.7c0,0-1.2,2.5,0,3.5"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M212.2,150c0,0-0.3-2.6,1.3-4.8c1.6-2.3,9.3-6,8.9-3.9c-0.7,3.2-3.2,5.6-5.8,7.3C214.7,149.9,213,151.3,212.2,150z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M215.2,146.4c0,0-0.2,1.6,1.1,1.3c0.7-0.1,0.7-1.3,0.7-1.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M208.8,147.2c0,0-2-1.8-2.8-2.1s-1.8,0-1.8,0"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M205.4,148.8c0,0,0.3,0.3,1.1,0.3c0.7,0,1.1-1.3-0.4-1.8"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M208.5,165.1l9.1-0.5c0,0,2.7-1.6,1.4-4.4c-1.3-2.8-3.1-3.1-5.4-3c-2.3,0.1-3.4,1.5-3.4,1.5"
      />
      <path
        display="inline"
        d="M210.6,161.4c0-1,4.2-1.3,4.7-0.7c0.5,0.6,1.5,1.9-0.7,1.9C212.4,162.5,210.6,162.4,210.6,161.4z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M211.8,142c0.8-0.4,1.5-0.8,2.1-1.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.5,0.3-1,0.6-1.4c0.5-0.5,1.3-0.5,1.9-0.9c0.7-0.5,0.9-1.4,1.3-2.1
		c0.2-0.4,0.5-0.6,0.8-0.9c0.8-0.8,1.6-1.6,2.4-2.4c0.3-0.3,0.6-0.6,0.9-0.7c0.5-0.2,1.1-0.2,1.6-0.4c1.1-0.5,1.6-1.9,2.6-2.4
		c0.5-0.3,1.1-0.3,1.6-0.6c0.8-0.5,1.2-1.6,2.1-2c0.3-0.1,0.6-0.1,0.9-0.3c0.9-0.3,1.4-1.4,2.3-1.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M207.8,141.1c-0.5,0.2-1-0.2-1.2-0.7c-0.2-0.5-0.3-1.1-0.7-1.4c-0.3-0.3-0.8-0.3-1-0.7c-0.1-0.2-0.1-0.4-0.3-0.6
		c-0.1-0.2-0.3-0.3-0.5-0.3c-0.9-0.3-1.7-0.6-2.6-0.8"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M235.3,168.2c-2.1,0.2-7.1,3.7-8.7,4.2s-4.3,0.7-5.5,1.1s-2.7,0.8-4.8,1.1s-5.3,2.1-6.2,2.2c-0.9,0.1-0.7-4.9-0.7-4.9"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="214.3"
        y1="175.2"
        x2="214.1"
        y2="170.4"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="218.1"
        y1="169.4"
        x2="218.1"
        y2="173.9"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="223.2"
        y1="168.9"
        x2="223.2"
        y2="172.9"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="227.6"
        y1="168.7"
        x2="227.6"
        y2="171.2"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="230.8"
        y1="166.6"
        x2="230.8"
        y2="169.4"
      />
      <path
        display="inline"
        fill="#00000F"
        stroke="#000000"
        strokeMiterlimit="10"
        d="M209.2,187.9c0,0,0.8-3.5,3.9-4.5
		c5.1-1.8,16.7-13.5,22.1-15.5l-0.1,2.1c-0.1,1.6-0.6,4.6-3.7,7c-3.7,2.9-8.7,5.2-14.8,8.4C213,187.4,209.2,187.9,209.2,187.9z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M118.6,117.4c0,0,0.3,3.2,0.3,5.4c0,2.2,0.8,4.2,0.8,4.2"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M104.1,111.4c0,0,1.6,0.1,3.4,0.9c1.8,0.8,2.8,1.7,3.1,2.7"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M131,112.2c0,0-3.8-0.6-5.7,1.7c-1.6,1.9-1.2,4.4-1.2,4.4"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M109.3,128.1c0,0-3.3-0.9-5,1.3c-0.9,1.2-0.6,4.1-0.6,4.1"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M122.4,128.7c2-1.7,6.9,1.3,3.2,4.7"
      />
      <path
        display="inline"
        d="M105.7,134.9c-0.5-0.1,0.2-2.9,2.3-3.1c2.1-0.2,2.8,0.8,2.6,2.3c-0.2,1.4-0.5-1.4-2.3-0.7
		C107.1,134.4,106.9,134.9,105.7,134.9z"
      />
      <path
        display="inline"
        d="M125.5,134.1c0.5-1.1-0.1-2.3-2-2.4c-1.9-0.2-2.3,0.6-2.7,2.1c-0.3,1.3,0.7-1.3,2.3-0.6
		C124.3,134,124.5,134.1,125.5,134.1z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M110.3,133.5c0,0,0.5,7.3,1.6,8.5c0.8,0.8,5.2,1.2,5.9-1.6c1.1-4.4,3.5-7.1,3.5-7.1"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M127.6,117.6c1.7-1.2,3.2-1.5,2.5-2.1c-0.7-0.6-2.7-2-3.7-0.3C124.8,117.9,125.3,119.2,127.6,117.6z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M109.1,119.5c-1,0-0.4-0.4-2.3-1s-3.5-1.6-2.8-2.2s2.8-1.1,4.2,0C110,117.5,110.2,119.5,109.1,119.5z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M209.4,146.6c0,0,0.3-0.6,0.1,1.7c-0.2,2.3-1.7,3.7-2,4.9c-0.2,1.3-0.3,3.9-0.9,4.2c-2.1,1.3-2.3,2.5-2.3,2.5s-1.2,1.8-2.6,2.1"
      />
    </g>
    <g id="chortle8">
      <path
        display="inline"
        fill="#00000F"
        stroke="#000000"
        strokeMiterlimit="10"
        d="M120.8,187.4c0,0,3.9,2.7,6.2,3.8
		c3.2,1.6,9,3.2,9.3,9c0.3,5.6-0.5,9.5-0.5,9.5h-5.6V222H88.4v-8.3h-7.6c0,0-0.1,0.1-0.1-7.1c0-9,5.9-11.7,10.9-13.3
		c2.9-0.9,10.2-5.9,10.2-5.9"
      />
      <path
        display="inline"
        d="M140.7,124.7c0,0,4.5-3.7,4.9-8c0.4-4.4,1.3-24.9,0-30.2c-1.4-5.3-4.9-8-4.9-12.2c0,0,1.2-4.3,0-6.8
		c-2.1-4.2-0.4-11.2-4.1-11.2s-4,3-6.1,0.2c-2.1-2.8-4.4-6.5-8.8-8.5s-10.7-2.4-13-4c-2.3-1.6-17.2-9.8-18.2,0s0,13.9-1.1,15.3
		s-3.4,3.1-3.8,6.1c-0.4,3-0.9,4.4-1.8,7.5c-0.8,3.1-4.5,7.9-3,19.4c1.2,9-1.1,16,1.3,21.6c2.4,5.8,1.7,9.5,2.4,11.8"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M102.3,193.4c0,0-2.1-13.6-3.8-19.1c-1.7-5.5-7.9-20.3-9.2-27c-1.7-8.5-2.3-14-2.3-14s-3.8-3-3.2-5.8c0.6-2.8,3.2-3.8,3.2-3.8
		s-1.7-13.6-1.7-16.8c0-3.2-1-9.3,0-9.2c3.5,0.4,11.9-13.3,15.5-10.9c2.5,1.7,5.7,7.4,11,7.2s11.1-6.4,12-8c0.9-1.5,4.3-2.4,6.2-1.1
		s2.4-2.3,5.5-1.1c3,1.1,5.7,2.8,5.6,14.3c0,7.9-0.6,26.5-0.6,26.5s2.1-0.9,2.1,2.1c0,3-0.8,4.9-2.1,5.3s-1.3,0.4-1.3,0.4
		s-2.2,10-6.1,20c-3.8,9.5-10.2,21.7-10.8,25.2s-2.4,15.2-2.4,15.2s-6.6,0.5-9.3,0.3C108.1,193.1,102.3,193.4,102.3,193.4z"
      />
      <path
        display="inline"
        fill="#00000F"
        stroke="#000000"
        strokeMiterlimit="10"
        d="M101.4,154c-1.4-0.1,0.8,2.6,6.3,2.7
		c2.8,0,1.8,1.3,6.9,1.3c4.9,0,2-0.5,7-1.2c3.7-0.5,5.5-2.2,4.3-2.9c-4.2-2.3-4.7-5.6-10.8-5.8C106.9,148,106.9,154.6,101.4,154z"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M255.1,165.7c0,0,1.3,0.3,2.3-1.6c3.4-6.2,6.3-23.2,9.7-35.3c0.5-1.7,1.3-11.2,1.6-12.4c0.3-1.3,2-5.1,1.8-7.1
		c-0.1-2-0.6-5.4-0.3-6.8c0.3-1.4,1.3-4.2,0.8-8c-0.4-3.8-2-6.6-1.7-8.3s0.7-9.5-0.4-12.1c-1.1-2.7-1.3-6.8-4.2-6.5
		c-0.7,0.1,0.1-3.1-2.1-5.4c-2.3-2.3-5.6-2.5-5.6-2.5s-0.8-3.2-3.2-4.4s-3.7-0.4-3.7-0.4s-3.2-4.1-7.8-4.4c-5.2-0.3-7.8,2-7.8,2
		s-0.7-0.4-2.4-1c-1.7-0.6-5.8,0.1-5.8,0.1s-2.8-3.3-8.9-2.4c-3.5,0.5-4,4-4,4s-5.8-0.3-8.5,2.3c-2.7,2.5-2,6.2-2,6.2s-5.9-0.1-8,3
		c-2.1,3.1-1,10.4-1,10.4s-3.7,2-3.5,5.9c0.1,4,2,6.6,2.8,6.6s1.1,8.8,5.4,11.7c3,2.1,10.6,1.4,10.6,1.4"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M234.5,52.5c0,0,2.5,1.3,2.8,3.7"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M249.9,54.6c0,0,0.1,1.8,1.3,3.1"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M256.9,59.7c0,0,0.4,1.4,0,2.7"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M198.3,69.1c0,0-3,1.4-2.8,7.2c0.1,3.5-0.4,4.5-0.4,4.5"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M194,85.1c0,0-0.7,2.5,0,4.1"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M205,91.4c0,0,3.6,7.5,7.6,10.4"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M232.9,91c0,0-0.6,3.8,0.6,6.1s-0.2,4-0.2,4"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M215.4,93.7c0,0,2.8,7,7.2,9.4"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M222.6,95.8c0,0,2.8,5.4,5.5,5.7"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M226.9,95.5c0,0,2.7,5.9,4.9,5.8"
      />
      <path
        display="inline"
        fill="#00000F"
        stroke="#000000"
        strokeMiterlimit="10"
        d="M256.2,185.3c0,0,12.9,2.6,13.8,11.3
		c0.6,5.5,0.1,12.9,0.1,12.9l-8.5,0.2l0.3,12.1l-33.9,0.2l0.6-11.7h-5.9c0,0,0.7-5.7,0.6-8.2c-0.7-11.3,12.9-12,13.8-12.7
		C238.2,188.7,256.2,185.3,256.2,185.3z"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M255.1,165.8c-0.1,1-0.8,16,1.9,21.5c1.3,2.8-9.1,6-14,7c-1.2,0.3-2.7-1.7-3.5-2.7c-1.3-1.7-2.8-3.1-3.8-4.4
		c-3.8-5.3-13.9-2-19,1.9c-3.2,2.4-8.7,3.7-9.4,1.4c-0.6-1.8-1.6-5.4-3.6-6.4c-1.7-0.8-1.8-3,0.9-2.8c0,0,6.1,0.2,9.7-1.7
		c6.1-3.2,11.4-9.5,13.9-7.9c5.7,3.8,8.3-7.1,7-7.1c-1.4,0-5.2,2.8-9.1,3.6c-3.9,0.8-5.3,0.6-9,1.4c-5.3,1.1-8.8,3-9.4,1.9
		c-0.6-1,1-6.3,0.4-6.2s-2.8,1.1-4.4,1.8c-1.7,0.7-5.3,0.2-4.7-1.5c0.6-1.9,2.2-3.1,1.7-4.2c-0.3-0.7,0.5-3.7,2.1-3.8
		c1.5-0.1,2.9-0.1,3.2-2.4c0.3-2.1-0.8-3.6-0.8-5.4c0-1.3-2.6-2.6-1.9-5c0.6-1.9-1-7.7-1.5-11.2c-0.6-3.5-1.1-7.7-1.2-12.9
		c-0.1-5.2,1.2-9.7,3.2-12.7c2.1-3,4-6.4,4.4-7.3c0.4-0.9,2.6-0.7,3.5,0c0.9,0.8,2.5,1.6,4,1.6c1.5,0,2.6-0.7,3.9-0.7
		c1.2,0,3.1,0.9,4.5,0.4c1.4-0.5,3.7-0.6,5.1-0.7c1.4-0.1,4.4-0.9,6,0.6c1.6,1.5,9.7,7.2,9.5,15.7s-4.5,16.2-2.5,22.4
		c1.1,3.6,3.3,5.8,3.8,4.6c0.5-1.2,0.6-5.6,2.7-5.8c2.1-0.2,3.3,1.2,3.4,5c0.1,3.8-0.8,8.2-0.3,8.8s2,1,1.8,3.9
		c-0.2,2.8-0.3,6.2,0.3,7.1C254.4,164.3,255.1,165.8,255.1,165.8"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M123.5,152.2c-3-2.9-5.3-4.3-8.8-4.2c-4.4,0.1-6.7,2.8-8.5,4.3L123.5,152.2z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M104.7,167.1c0,0,0.6,1.8,5,1.8c4.4,0,4.2,0.1,6.2,0.1c2-0.1,3.7-0.7,3.7-0.7"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M97.8,146.9c0,0-1.7,3.2-1.8,6c-0.1,2.2,0.2,2.6,0.2,2.6"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M127.9,149.1c0,0,0.7,1.1,0.8,2.8c0.1,1.7-0.2,3.4-0.3,3.4"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M141,126.9c0,0-0.8,3.5,0,2.8"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M108.1,158.8c1.1,0.1,2.3,1.8,6.1,1.9c3.7,0.1,4.3-1,5.8-1.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M113.9,149.6c0,0,1.3-0.4,2.8,0.2"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M81.8,213.7c0,0-0.6,7,0,8.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M134.9,209.8c0,0,0.4,3.5,0,6.1c-0.4,2.6-0.4,6.1-0.4,6.1"
      />

      <polygon
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
		125.6,154.7 104.9,154.8 106.5,152.3 123.2,152.1 	"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="116.6"
        y1="152.7"
        x2="116.7"
        y2="154.7"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="120.1"
        y1="152.7"
        x2="120.5"
        y2="154.5"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="108.5"
        y1="152.9"
        x2="108"
        y2="154.6"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="112.9"
        y1="152.8"
        x2="112.6"
        y2="154.6"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M234.8,160.8c0,0,1.3-0.5,2.1-0.4c1.6,0.1,2.6,0.8,2.4,2.7"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M249.6,143c-1.6-0.6-1.1,3.7,0,5.4c0.8,1.3,0.4,4.7-2.2,4.4"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M223.9,210c0,0-0.4,4.1-0.3,5.5c0.1,1.4,0.3,6.4,0.3,6.4"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="268.7"
        y1="209"
        x2="269"
        y2="221.5"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M86.9,126.7c0,0-1.2,2.5,0,3.5"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M212.1,150.2c0,0-0.3-2.6,1.3-4.8c1.6-2.3,9.3-6,8.9-3.9c-0.7,3.2-3.2,5.6-5.8,7.3C214.6,150,212.9,151.5,212.1,150.2z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M215.1,146.6c0,0-0.2,1.6,1.1,1.3c0.7-0.1,0.7-1.3,0.7-1.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M208.8,147.2c0,0-2-1.8-2.8-2.1s-1.8,0-1.8,0"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M205.1,149.1c0,0,0.4,0.3,1.3,0.3c0.8,0,1.3-1.5-0.5-2.1"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M208.9,165.3l8.7-0.6c0,0,2.7-1.6,1.4-4.4c-1.3-2.8-3.1-3.1-5.4-3c-2.3,0.1-3.4,1.5-3.4,1.5"
      />
      <path
        display="inline"
        d="M210.6,161.4c0-1,4.2-1.3,4.7-0.7c0.5,0.6,1.5,1.9-0.7,1.9C212.4,162.5,210.6,162.4,210.6,161.4z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M212.5,141.6c0.8-0.3,1.5-0.8,2.1-1.3c0.3-0.3,0.6-0.6,0.8-0.9c0.2-0.4,0.3-1,0.6-1.3c0.5-0.5,1.3-0.5,1.8-0.8
		c0.7-0.5,0.8-1.4,1.3-2.1c0.2-0.3,0.5-0.6,0.8-0.9c0.8-0.8,1.5-1.5,2.3-2.3c0.3-0.3,0.6-0.5,0.9-0.7c0.5-0.2,1-0.2,1.5-0.4
		c1.1-0.4,1.5-1.8,2.5-2.3c0.5-0.2,1-0.3,1.5-0.6c0.8-0.5,1.2-1.6,2-1.9c0.3-0.1,0.6-0.1,0.9-0.2c0.8-0.3,1.4-1.4,2.2-1.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M207.8,141.1c-0.4,0.2-0.9-0.2-1-0.7c-0.2-0.5-0.3-1-0.6-1.3c-0.3-0.2-0.7-0.3-0.9-0.7c-0.1-0.2-0.1-0.4-0.2-0.5
		c-0.1-0.2-0.3-0.2-0.4-0.3c-0.7-0.3-1.5-0.6-2.3-0.8"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M234.4,167.6c-1.4,1.6-6.1,4.3-7.7,4.8s-4.3,0.7-5.5,1.1s-2.7,0.8-4.8,1.1s-5.3,2.1-6.2,2.2c-0.9,0.1-0.7-4.9-0.7-4.9"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="214.3"
        y1="175.2"
        x2="214.1"
        y2="170.4"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="218.1"
        y1="169.4"
        x2="218.1"
        y2="173.9"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="223.2"
        y1="168.9"
        x2="223.2"
        y2="172.9"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="227.5"
        y1="168.2"
        x2="227.5"
        y2="170.7"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="230.8"
        y1="166.6"
        x2="230.8"
        y2="169.4"
      />
      <path
        display="inline"
        fill="#00000F"
        stroke="#000000"
        strokeMiterlimit="10"
        d="M203.6,181.3c0,0,2.2-1.8,5.3-3.4
		c4.8-2.5,19.5-5.6,26-11l-0.9,2.9c-0.1,1.6-3.2,3.1-6.7,4.6c-4.3,1.9-7.8,2.5-13.9,5.7C209.9,182.1,203.6,181.3,203.6,181.3z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M118.4,118.9c0,0,0.3,2.9,0.3,4.9c0,2.1,1,3.9,1,3.9"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M104.3,112.2c0,0,1.4-0.3,3.1,0.5c1.7,0.8,2.7,1.6,2.9,2.6"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M131,112.4c0,0-3.5-0.7-5.5,1.6c-1.6,1.9-1.5,4.5-1.5,4.5"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M109.3,128.8c0,0-3.2-1.3-5,0.9c-1,1.2-0.6,3.7-0.6,3.7"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M122.4,128.7c2.5-1.3,6.4,2.6,3.2,4.7"
      />
      <path
        display="inline"
        d="M105.7,134.9c-0.5-0.1,0.2-2.9,2.3-3.1c2.1-0.2,2.8,0.8,2.6,2.3c-0.2,1.4-0.5-1.4-2.3-0.7
		C107.1,134.4,106.9,134.9,105.7,134.9z"
      />
      <path
        display="inline"
        d="M125.5,134.1c0.5-1.1-0.1-2.3-2-2.4c-1.9-0.2-2.3,0.6-2.7,2.1c-0.3,1.3,0.7-1.3,2.3-0.6
		C124.3,134,124.5,134.1,125.5,134.1z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M110.3,133.5c0,0,0.8,7.3,1.9,8.5c0.8,0.8,4.7,2,5.6-1.6c1.1-4.4,3.5-7.1,3.5-7.1"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M127.6,117.8c1.7-1.2,3.2-1.5,2.5-2.1c-0.7-0.6-2.7-2-3.7-0.3C124.8,118.1,125.3,119.4,127.6,117.8z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M109.1,119.7c-1,0-0.4-0.4-2.3-1c-1.9-0.7-3.5-1.6-2.8-2.2s2.8-1.1,4.2,0C110,117.8,110.2,119.7,109.1,119.7z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M209.4,146.6c0,0,0.3-0.6,0.1,1.7c-0.2,2.3-1.7,3.7-2,4.9c-0.2,1.3-0.3,3.9-0.9,4.2c-2.1,1.3-2.3,2.5-2.3,2.5s-1.2,1.8-2.6,2.1"
      />
    </g>
    <g id="chortle9">
      <path
        display="inline"
        fill="#00000F"
        stroke="#000000"
        strokeMiterlimit="10"
        d="M120.8,187.4c0,0,4.2,2.3,6.2,3.8
		c2.4,1.8,7.5,4.8,7.8,10.6c0.2,4.2,0.7,6.6,0.9,7.5c0.1,0.2-0.1,0.5-0.4,0.5h-5.1V222H88.4V213h-7.6c0,0-0.5-1.1-0.5-4.7
		c0-8,6.6-12.1,11.9-15.5c3.1-2,9.7-5.8,9.7-5.8"
      />
      <path
        display="inline"
        fill="#00000F"
        d="M140.7,124.7c0,0,4.5-3.7,4.9-8c0.4-4.4,1.3-24.9,0-30.2c-1.4-5.3-4.9-8-4.9-12.2
		c0,0,1.2-4.3,0-6.8c-2.1-4.2-0.4-11.2-4.1-11.2s-4,3-6.1,0.2c-2.1-2.8-4.4-6.5-8.8-8.5s-10.7-2.4-13-4c-2.3-1.6-17.2-9.8-18.2,0
		s0,13.9-1.1,15.3s-3.4,3.1-3.8,6.1c-0.4,3-0.9,4.4-1.8,7.5c-0.8,3.1-4.5,7.9-3,19.4c1.2,9-1.1,16,1.3,21.6
		c2.4,5.8,1.7,9.5,2.4,11.8"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M102.3,193.4c0,0-2.1-13.6-3.8-19.1c-1.7-5.5-7.5-20.9-8.8-27.6c-1.7-8.5-2.7-13.4-2.7-13.4s-3.8-3-3.2-5.8
		c0.6-2.8,3.2-3.8,3.2-3.8s-1.7-13.6-1.7-16.8c0-3.2-1-9.3,0-9.2c3.5,0.4,11.9-13.3,15.5-10.9c2.5,1.7,5.7,7.4,11,7.2s11.1-6.4,12-8
		c0.9-1.5,4.3-2.4,6.2-1.1s2.4-2.3,5.5-1.1c3,1.1,4.7,2.9,5.6,14.3c0.9,10-0.3,27.2-0.6,26.5c0,0,2.4-1.6,2.4,1.4
		c0,3-0.5,5.3-1.8,5.6c-1.3,0.4-1.9,0.7-1.9,0.7s-2.9,10-6.8,20c-3.8,9.5-9.9,20.9-10.4,24.4s-2.1,16-2.1,16s-6.6,0.5-9.3,0.3
		C108.1,193.1,102.3,193.4,102.3,193.4z"
      />
      <path
        display="inline"
        fill="#00000F"
        stroke="#000000"
        strokeMiterlimit="10"
        d="M102.1,153.5c-1.4,0.2,0.3,1.3,5.8,1.4
		c2.8,0,1,0,6.1,0c4.9,0,4.4,0,7.3-0.1c2.7-0.1,5.4-0.9,4.2-1.7c-4.2-2.6-4.4-5.6-10.4-5.8C106.9,147.1,105.2,153.2,102.1,153.5z"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M255.1,165.7c0,0,1.3,0.3,2.3-1.6c3.4-6.2,5.7-22.7,9.1-34.9c0.5-1.7,1.8-11.6,2.1-12.8c0.3-1.3,2-5.1,1.8-7.1
		c-0.1-2-0.6-5.4-0.3-6.8c0.3-1.4,1.3-4.2,0.8-8c-0.4-3.8-2-6.6-1.7-8.3s0.7-9.5-0.4-12.1c-1.1-2.7-1.3-6.8-4.2-6.5
		c-0.7,0.1,0.1-3.1-2.1-5.4c-2.3-2.3-5.6-2.5-5.6-2.5s-0.8-3.2-3.2-4.4s-4.2,0.1-4.2,0.1s-3.1-4.6-7.6-4.9c-5.2-0.3-7.3,2-7.3,2
		s-0.7-0.4-2.4-1c-1.7-0.6-5.5,0.8-5.5,0.8s-3.1-4-9.2-3.1c-3.5,0.5-4,4-4,4s-5.4-0.3-8,2.3c-2.7,2.5-2.4,6.2-2.4,6.2
		s-5.6,0.8-7.8,4c-2.1,3.1-1.3,9.5-1.3,9.5s-3.7,2-3.5,5.9c0.1,4,2,6.6,2.8,6.6s2.5,7.2,6.8,10.2c3,2.1,9.2,3,9.2,3"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M234.5,52.5c0,0,2.5,1.3,2.8,3.7"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M249.3,55.1c0,0,0.7,1.3,1.9,2.6"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M256.9,59.7c0,0,0.4,1.4,0,2.7"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M198.3,69.1c0,0-3,1.4-2.8,7.2c0.1,3.5-0.4,4.5-0.4,4.5"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M194.2,84.6c0,0-0.7,2.5,0,4.1"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M205,91.4c0,0,3.6,7.5,7.6,10.4"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M232.9,91c0,0-0.6,3.8,0.6,6.1s-0.2,4-0.2,4"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M215.4,93.7c0,0,2.8,7,7.2,9.4"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M222.6,95.8c0,0,2.8,5.4,5.5,5.7"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M226.9,95.5c0,0,2.7,5.9,4.9,5.8"
      />
      <path
        display="inline"
        fill="#00000F"
        stroke="#000000"
        strokeMiterlimit="10"
        d="M256.2,185.3c0,0,12.9,2.6,13.8,11.3
		c0.6,5.5,0.1,12.9,0.1,12.9l-8.5,0.2l0.3,12.1l-33.9,0.2l0.6-11.7h-5.9c0,0,0.7-5.7,0.6-8.2c-0.7-11.3,12.9-12,13.8-12.7
		C238.2,188.7,256.2,185.3,256.2,185.3z"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M255.1,165.8c-0.1,1-0.1,15.7,1.9,21.5c1.4,4.2-9.7,6.1-14.6,7.2c-1.2,0.3-2.2-1.6-3-2.5c-1.3-1.7-3.1-3.7-4-5
		c-3.8-5.3-13.3-1.1-18.4,2.8c-3.2,2.4-9.2,3.8-9.6,1.5c-0.5-2.6-0.1-4.6-2.1-5.6c-1.7-0.8-3-4-0.3-3.9c0,0,7.5,1.3,11.1-0.6
		c6.1-3.2,9.8-11.2,12.3-9.5c5.7,3.8,8.3-7.1,7-7.1c-1.4,0-5.2,2.8-9.1,3.6c-3.9,0.8-5.3,0.6-9,1.4c-5.3,1.1-8.8,3-9.4,1.9
		c-0.6-1,0.8-5.8,0.2-5.7s-3.1,1-4.7,1.7c-1.7,0.7-4.8-0.2-4.2-1.9c0.6-1.9,2.2-3.1,1.7-4.2c-0.3-0.7,0.5-3.7,2.1-3.8
		c1.5-0.1,2.9-0.1,3.2-2.4c0.3-2.1-0.8-3.6-0.8-5.4c0-1.3-2.6-2.6-1.9-5c0.6-1.9-1-7.7-1.5-11.2c-0.6-3.5-1.1-7.7-1.2-12.9
		c-0.1-5.2,1.2-10.5,3.2-13.5c2.1-3,4-5.6,4.4-6.6c0.4-0.9,2.6-0.7,3.5,0c0.9,0.8,2.5,1.6,4,1.6c1.5,0,2.6-0.7,3.9-0.7
		c1.2,0,3.1,0.9,4.5,0.4c1.4-0.5,3.7-0.6,5.1-0.7c1.4-0.1,4.4-0.9,6,0.6c1.6,1.5,9.7,7.2,9.5,15.7s-4.5,16.2-2.5,22.4
		c1.1,3.6,3.3,5.8,3.8,4.6c0.5-1.2,0.6-5.6,2.7-5.8c2.1-0.2,3.3,1.2,3.4,5c0.1,3.8-0.8,8.2-0.3,8.8s2,1,1.8,3.9
		c-0.2,2.8-0.3,6.2,0.3,7.1C254.4,164.3,255.1,165.8,255.1,165.8"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M123.9,151.9c-3.5-3.5-5.4-4.7-9.2-4.7c-5.1,0.1-7.9,2.9-9.7,4.7L123.9,151.9z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M105,165.5c0,0,0.6,1.5,4.8,1.5s4,0.1,6,0.1s3.5-0.6,3.5-0.6"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M97.8,146.9c0,0-1.7,3.2-1.8,6c-0.1,2.2,0.2,2.6,0.2,2.6"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M127.9,149.1c0,0,0.7,1.1,0.8,2.8c0.1,1.7-0.2,3.4-0.3,3.4"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M141,126.9c0,0-0.8,3.5,0,2.8"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M108.7,157.2c1,0.1,1.4,0.4,4.6,0.4c1.8,0,2.1-0.1,3.9-0.4"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M113.9,149c0,0,1.3-0.4,2.8,0.2"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M81.8,213.7c0,0-0.6,7,0,8.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M134.9,209.8c0,0,0.8,4.1,0.5,6.7c-0.4,2.6-0.1,5.5-0.1,5.5"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M125.6,153.7c0,0-0.7,0.7-3.1,0.9c-2.4,0.3-12,0.3-15.4,0.1c-3.4-0.2-4.8-0.8-4.8-0.8s3.8-2.7,4.1-3.1c0.4-0.4-0.8,1,7.9,1
		s6.7-2.5,7.9-1.5S125.6,153.7,125.6,153.7z"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="116.6"
        y1="152.5"
        x2="116.7"
        y2="154.8"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="120.1"
        y1="151.6"
        x2="120.5"
        y2="154.5"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="108.5"
        y1="152.3"
        x2="108"
        y2="154.7"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="112.7"
        y1="152.1"
        x2="112.6"
        y2="154.6"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M235.1,161.3c0,0,1.1-0.5,1.7-0.3c1.5,0.5,2,1.4,1.7,3.2"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M249.6,143c-1.6-0.6-1.1,3.7,0,5.4c0.8,1.3,0.4,4.7-2.2,4.4"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M223.9,210c0,0-0.4,4.1-0.3,5.5c0.1,1.4,0.3,6.4,0.3,6.4"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="268.7"
        y1="209"
        x2="269"
        y2="221.5"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M86.9,126.7c0,0-1.2,2.5,0,3.5"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M211.8,150.1c0,0,0.2-2.1,1.8-4.3c1.6-2.3,8.8-5.4,8.4-3.3c-0.7,3.2-2.7,5-5.3,6.7C214.8,150.5,212.6,151.5,211.8,150.1z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M214.2,147.3c0,0-0.2,1.6,1.1,1.3c0.7-0.1,0.7-1.3,0.7-1.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M208.6,147.9c0,0-2-1.8-2.8-2.1c-0.8-0.4-1.8,0-1.8,0"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M205.1,149.2c0,0,0.3,0.3,1,0.3c0.6,0,1.1-1.4-0.4-1.9"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M208.8,165.7l8.2-0.6c0,0,2.7-2,1.5-4.7c-1.2-2.7-2.9-3-5-2.9c-2.1,0.1-3.2,1.5-3.2,1.5"
      />
      <path
        display="inline"
        d="M210.6,161.4c0-1,4.2-1.3,4.7-0.7c0.5,0.6,1.5,1.9-0.7,1.9C212.4,162.5,210.6,162.4,210.6,161.4z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M212.1,141.9c0.7-0.3,1.4-0.8,2-1.3c0.3-0.3,0.6-0.6,0.7-0.9c0.2-0.4,0.3-1,0.6-1.3c0.5-0.5,1.2-0.4,1.8-0.8c0.7-0.4,0.8-1.3,1.2-2
		c0.2-0.3,0.5-0.6,0.8-0.9c0.7-0.8,1.5-1.5,2.2-2.3c0.3-0.3,0.5-0.5,0.9-0.7c0.5-0.2,1-0.2,1.5-0.4c1-0.4,1.5-1.8,2.5-2.3
		c0.5-0.2,1-0.3,1.5-0.5c0.8-0.5,1.1-1.5,2-1.9c0.3-0.1,0.6-0.1,0.8-0.2c0.8-0.3,1.3-1.3,2.2-1.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M207.6,141.8c-0.4,0.2-0.8-0.2-1-0.7s-0.3-1-0.6-1.3c-0.3-0.2-0.6-0.3-0.8-0.7c-0.1-0.2-0.1-0.4-0.2-0.5c-0.1-0.2-0.3-0.2-0.4-0.3
		c-0.7-0.3-1.4-0.6-2.2-0.8"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M234.4,167.6c-1.4,1.6-6.1,4.3-7.7,4.8s-4.3,0.7-5.5,1.1s-2.7,0.8-4.8,1.1s-5.3,2.1-6.2,2.2c-0.9,0.1-0.7-4.9-0.7-4.9"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="214.3"
        y1="175.2"
        x2="214.1"
        y2="170.4"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="218.1"
        y1="169.4"
        x2="218.1"
        y2="173.9"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="223.2"
        y1="168.9"
        x2="223.2"
        y2="172.9"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="227.5"
        y1="168.2"
        x2="227.5"
        y2="170.7"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="230.8"
        y1="166.6"
        x2="230.8"
        y2="169.4"
      />
      <path
        display="inline"
        fill="#00000F"
        stroke="#000000"
        strokeMiterlimit="10"
        d="M203.6,182.4c0,0,2.3-2.9,5.4-4.5
		c4.8-2.5,19.5-5.6,26-11l-0.9,2.9c-0.1,1.6-2.1,4.6-5.6,6.1c-4.3,1.9-7.3,2.6-13.4,5.8C211.6,183.7,203.6,182.4,203.6,182.4z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M118.4,118.9c0,0,0.3,2.9,0.3,4.9c0,2.1,1,3.9,1,3.9"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M103.5,111.9c0,0,1.7-0.4,3.4,0.4c1.7,0.8,3.1,2,3.4,3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M131,112.4c0,0-3.5-0.7-5.5,1.6c-1.6,1.9-1.5,4.5-1.5,4.5"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M109.3,128.8c0,0-3.2-1.3-5,0.9c-1,1.2-0.6,3.7-0.6,3.7"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M122.4,128.7c2.5-1.3,6.4,2.6,3.2,4.7"
      />
      <path
        display="inline"
        d="M105.7,134.9c-0.5-0.1,0.2-2.9,2.3-3.1c2.1-0.2,2.8,0.8,2.6,2.3c-0.2,1.4-0.5-1.4-2.3-0.7
		C107.1,134.4,106.9,134.9,105.7,134.9z"
      />
      <path
        display="inline"
        d="M125.5,134.1c0.5-1.1-0.1-2.3-2-2.4c-1.9-0.2-2.3,0.6-2.7,2.1c-0.3,1.3,0.7-1.3,2.3-0.6
		C124.3,134,124.5,134.1,125.5,134.1z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M110.3,133.5c0,0,0.8,7.3,1.9,8.5c0.8,0.8,4.7,2,5.6-1.6c1.1-4.4,3.5-7.1,3.5-7.1"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M127.6,117.8c1.7-1.2,3.2-1.5,2.5-2.1c-0.7-0.6-2.7-2-3.7-0.3C124.8,118.1,125.3,119.4,127.6,117.8z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M109.1,119.7c-1,0-0.4-0.4-2.3-1c-1.9-0.7-3.5-1.6-2.8-2.2s2.8-1.1,4.2,0C110,117.8,110.2,119.7,109.1,119.7z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M208.9,147.3c0,0,0.9-0.5,0.1,1.7c-0.7,1.9-1.4,2.7-1.6,4c-0.2,1.3-0.6,4.8-1.2,5.2c-2.1,1.3-2.3,2.5-2.3,2.5s-1.2,1.8-2.6,2.1"
      />
    </g>
    <g id="chortle10">
      <path
        display="inline"
        stroke="#00000F"
        strokeMiterlimit="10"
        d="M120.8,187.4c0,0,3.6,2.5,5.7,4c2.6,1.9,9.3,5.9,9.3,11.6
		c0,5.6,0.1,6.8,0.1,6.8h-5.6V222H88.4v-9.3h-7.6c0,0-0.5,1.8-0.6-5.4c-0.1-5,5.7-10,9-12.7c3.5-2.8,12.7-7.5,12.7-7.5"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M123.8,152.6c-2.4-2.6-5.7-5.5-10.1-5.3c-5.4,0.6-7.4,4.5-10,5.9L123.8,152.6z"
      />
      <path
        display="inline"
        d="M140.7,124.7c0,0,3.1-4.1,4.9-8c3-6.3,0.3-23.6,0-30.2c-0.3-5.5-4.9-8-4.9-12.2c0,0,1.2-4.3,0-6.8
		c-2.1-4.2-0.7-11.9-4.3-11.9s-3.7,3.8-5.8,0.9c-2.1-2.8-4.4-6.5-8.8-8.5s-10.7-2.4-13-4c-2.3-1.6-17.2-9.8-18.2,0s0,13.9-1.1,15.3
		s-2.7,3.9-3.2,6.8c-0.4,3-2.4,2.4-3.2,5.5c-0.8,3.1-3.6,13.7-3.8,26c-0.1,9,0.4,10.7,2.8,16.4c2.4,5.8,1.7,9.5,2.4,11.8"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M102.3,193.4c0,0-2.5-13.5-4.2-19c-1.7-5.5-7.4-20.4-8.8-27.1c-1.7-8.5-2.3-14-2.3-14s-3.8-3-3.2-5.8c0.6-2.8,3.2-3.8,3.2-3.8
		s-1.7-13.6-1.7-16.8c0-3.2-1-9.1,0-9.2c4.5-0.4,12.5-13.5,16.1-11.1c2.5,1.7,7.2,7.5,12.4,7.3c5.3-0.2,9.1-6.4,10-7.9
		c0.9-1.5,4.3-2.4,6.2-1.1s2.4-2.3,5.5-1.1c3,1.1,5.7,2.8,5.6,14.3c0,7.9-0.6,26.5-0.6,26.5s2.1-0.9,2.1,2.1c0,3-0.8,4.9-2.1,5.3
		s-1.3,0.4-1.3,0.4s-2.9,9.6-6.8,19.6c-3.8,9.5-10,22-10.5,25.5s-2,15.3-2,15.3s-6.6,0.5-9.3,0.3
		C108.1,193.1,102.3,193.4,102.3,193.4z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeMiterlimit="10"
        d="M102.9,153.5c-2.2,0.5,0.2-0.1,5,0.6
		c1.5,0.2,3,0.8,6.3,0.8c4.2-0.1,3.4,1,7.1,0c1.6-0.4,5.4-0.4,4.4-1.1c-3.7-2.8-6.5-7-12-6.5C106.5,148,106.9,152.7,102.9,153.5z"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M255.6,165.2c0,0,0.9,0.1,1.9-1.7c3.4-6.2,6.2-22.4,9.6-34.5c0.5-1.6,1.3-11.1,1.6-12.3s2-5,1.8-7c-0.1-2-0.3-5.5,0-6.9
		c0.3-1.4,1-4.1,0.6-7.8c-0.4-3.8-1.7-6.3-1.4-8c0.3-1.7,1.3-9.7,0.1-12.3c-1.1-2.7-3.5-6-4.2-6c-0.7,0-0.4-3.3-2.6-5.5
		c-2.3-2.2-5.4-3.2-5.4-3.2s-1.7-3.2-4.1-4.3c-2.4-1.1-3.7-0.8-3.7-0.8s-4.1-3.8-8.6-4.1c-4.7-0.4-5.7,2.5-5.7,2.5s-2.1-1-3.8-1.6
		c-1.7-0.6-5.1,1.1-5.1,1.1s-2.6-3.8-8.7-2.9c-3.5,0.5-4.8,4.2-4.8,4.2s-5.1-0.5-7.8,2.1c-2.7,2.5-2.6,6.3-2.6,6.3s-5.8,0.4-7.9,3.5
		c-2.1,3.1-1.4,11.4-1.4,11.4s-2.7,0.6-2.5,4.5s1.9,7,2.7,7c0.8,0,1.3,8.2,4.4,10.1c3.1,1.8,11.2,2.3,11.2,2.3"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M235.7,53.5c0,0,0.7,1.1,0.9,3.5"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M249.9,55.3c0,0,0.1,1.8,1.3,3.1"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M257.5,60c0,0,0.4,1.4,0,2.7"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M198.3,69.1c0,0-3,1.4-2.8,7.2c0.1,3.5-0.4,4.5-0.4,4.5"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M194.2,84.6c0,0-1,3.2-0.3,4.7"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M206,91.4c0,0,3.6,7.5,7.6,10.4"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M232.9,91c0,0-0.6,3.8,0.6,6.1s-0.2,4-0.2,4"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M215.4,93.7c0,0,2.8,7,7.2,9.4"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M222.6,95.8c0,0,2.8,5.4,5.5,5.7"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M227.7,95.8c0,0,2.3,5.3,4.2,5.2"
      />
      <path
        display="inline"
        fill="#00000F"
        stroke="#000000"
        strokeMiterlimit="10"
        d="M256.2,187c0,0,12.8,1.6,13.7,10.3
		c0.6,5.5,0.3,12.3,0.3,12.3l-8.5,0.2l0.3,12.1l-33.9,0.2l1.4-12.5h-5.9c0,0-0.1-4.9-0.2-7.4c-0.5-10.4,13-10.9,14-11.6
		C238.3,189.8,256.2,187,256.2,187z"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M255.6,165.3c-0.1,1-2,17.5,1.5,23.2c1.6,2.6-10.2,5.8-15.1,6.8c-1.2,0.3-2.4-0.4-2.9-1.5c-0.8-1.9-1.8-4.3-2.5-5.5
		c-3.3-4.9-11-3.1-21.7,0.5c0,0-7.8,2.4-8.5-0.8c-0.7-3.1,0.8-5-0.5-5.9c-1.6-1.1-2.3-4.2,0.4-4c0,0,3.3,0.3,7.1-1.2
		c4.5-1.8,12-2.4,14.9-3.4c8.3-2.7,9.3-8,8-8c-1.4,0-7,3.5-10,3.9c-3.9,0.4-5.4,0.7-9.1,1.3c-5.1,0.8-8.8,2.9-9.3,1.8
		c-0.6-1,0.5-7.2-0.1-7.2c-0.7,0.1-2.1,0.9-3.7,1.6c-1.7,0.7-5.2,0.6-4.7-1.1c0.6-1.9,1.9-3.4,1.4-4.6c-0.3-0.7,0.5-3.7,2.1-3.8
		c1.5-0.1,2.8,0,3.1-2.4c0.3-2.1-2.3-4-2-5.7c0.2-1.4-1-2.2-0.5-4.4c0.5-1.9-1.3-7.9-1.9-11.4c-0.6-3.5-1-7.8-1-13
		c-0.1-5.2,0.9-10,2.9-13c2.1-3,4.3-6.1,4.7-7c0.4-0.9,2.6-0.7,3.5,0c0.9,0.8,2.5,1.6,4,1.6c1.5,0,2.6-0.7,3.9-0.7
		c1.2,0,3.3,2.4,4.7,1.9c1.4-0.5,3.5-2.1,4.9-2.2c1.4-0.1,4.4-0.9,6,0.6c1.6,1.5,9.7,7.2,9.5,15.7s-4.5,16.2-2.5,22.4
		c1.1,3.6,3.3,5.8,3.8,4.6c0.5-1.2,0.6-5.6,2.7-5.8c2.1-0.2,3.3,1.2,3.4,5c0.1,3.8-0.8,8.2-0.3,8.8s2,1,1.8,3.9
		c-0.2,2.8,0,5.6,0.4,6.8C254.2,164.1,255.6,165.3,255.6,165.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M105.5,165.2c0,0,0.8,2.1,4.8,2.1s3.8,0.1,5.6,0c1.8-0.1,3.2-1.3,3.2-1.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M97.7,146.7c0,0-1.6,2.6-1.6,5.3c-0.1,2.2,0,3.3,0,3.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M127.7,148.7c0,0,0.9,1.2,1,3c0.1,1.9,0,3.8,0,3.8"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M141,126.9c0,0-0.8,3.5,0,2.8"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M108.7,156.5c1.3,0.1,1.3,1.1,5.7,1.2c4.4,0.1,5.1-1,6.9-1.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M111.1,150.4c0,0,1.1-0.8,2.6-0.9"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M81.8,213.7c0,0-0.6,7,0,8.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M134.9,209.8c0,0,0.4,3.5,0,6.1c-0.4,2.6-0.4,6.1-0.4,6.1"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="116.8"
        y1="153"
        x2="116.9"
        y2="154.8"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="120.3"
        y1="152.9"
        x2="120.7"
        y2="154.6"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="108.7"
        y1="153.1"
        x2="108.5"
        y2="154"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="113.1"
        y1="153.1"
        x2="112.7"
        y2="154.7"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M236.3,162.2c0,0,1.2-0.9,1.9-0.6c1.2,0.4,1.8,0.9,2.3,2.4"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M250,145.1c-2.7-0.3-1.9,3.6,0,4.9c1.2,0.8,0.4,4.3-2.3,4"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M223.9,210c0,0-0.4,4.1-0.3,5.5c0.1,1.4,0.3,6.4,0.3,6.4"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="268.7"
        y1="209"
        x2="269"
        y2="221.5"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M209.4,146.6c0,0,0-0.6,0.1,1.7s-1.2,3.7-1.8,4.9c-0.5,1.2-0.5,3.9-1.1,4.2c-2.1,1.3-2.3,2.5-2.3,2.5s-1.2,1.8-2.6,2.1"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M86.9,126.7c0,0-1.2,2.5,0,3.5"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M211.8,151.1c0,0-0.2-2.4,1.4-4.7c1.6-2.3,9.2-5.9,8.8-3.9c-0.7,3.2-3.2,6.2-5.8,7.3C214.2,150.7,213.3,151.7,211.8,151.1z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M214.1,147.7c0,0-0.2,1.6,1.1,1.3c0.7-0.1,0.7-1.3,0.7-1.3"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M208.6,148.8c0,0-2-1.8-2.8-2.1c-0.8-0.4-1.8,0-1.8,0"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M205.4,150.4c0,0,0.4,0.3,1.2,0.3c0.8,0,1.3-1.6-0.5-2.2"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M208.2,166l9.5-0.4c0,0,1.8-1.8,0.5-4.6c-1.3-2.8-3.2-2.9-5.5-3c-2-0.1-2.3,1.1-2.4,1.1"
      />
      <path
        display="inline"
        d="M209.5,162.2c0-1,4.2-1.3,4.7-0.7c0.5,0.6,1.5,1.9-0.7,1.9C211.4,163.3,209.5,163.1,209.5,162.2z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M211.9,142c0.8-0.4,1.5-0.8,2.1-1.4c0.3-0.3,0-0.3,0.2-0.7c0.2-0.5,0.9-1.3,1.2-1.7c0.5-0.5,1.3-0.5,1.9-0.9c0.7-0.5,1-0.8,1.5-1.5
		c0.2-0.4,0.5-0.6,0.8-0.9c0.8-0.8,1.8-1.2,2.6-2c0.3-0.3,0.6-0.6,0.9-0.7c0.5-0.2,1.1-0.2,1.6-0.4c1.1-0.5,1.2-2,2.3-2.5
		c0.5-0.3,0.9-0.9,1.4-1.2c0.8-0.5,0.7-1,1.6-1.4c0.3-0.1,0.8-0.4,1.1-0.5c0.9-0.3,1.4-1.4,2.3-1.4"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M207.9,142.1c-0.5,0.2-1-0.2-1.2-0.7s-0.3-1-0.7-1.3c-0.3-0.2-0.8-0.3-1-0.6c-0.1-0.2-0.1-0.4-0.3-0.5c-0.1-0.2-0.3-0.2-0.5-0.3
		c-0.9-0.3-1.7-0.6-2.6-0.8"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M235.1,163.7c0.4,1.1,5.2,2.7-3.1,8.2"
      />
      <path
        display="inline"
        fill="#00000F"
        d="M205,178c1.5-1.3,26.5-6.7,28.8-8.1l0,0c-0.1,1.6-1.9,1.9-5.4,3.5
		c-3.6,1.6-8.6,1.3-14.9,3.5C209,177.6,204.3,178.6,205,178z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M118.3,119.7c0,0,0.1,1.6,0.1,3.3s1.1,4,1.1,4"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M104.1,111.9c0,0,1.6,0.1,3.4,0.9c1.8,0.8,2.8,1.7,3.1,2.7"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M131,113c0,0-3.8-0.6-5.7,1.5c-1.6,1.7-1.2,4-1.2,4"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M108.8,128.4c0,0-2.4-1.4-4.7,1.7c-1,1.3-0.6,4.5-0.6,4.5"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M122.7,128.7c1.7-1.5,5.6,1.3,3.1,5.1"
      />
      <path
        display="inline"
        d="M105.8,134.7c-0.5-0.1,0.2-2.9,2.2-3.1c2.1-0.2,2.8,0.8,2.6,2.3c-0.2,1.4-0.5-1.4-2.2-0.7
		C107.2,134.2,107,134.7,105.8,134.7z"
      />
      <path
        display="inline"
        d="M125.2,134.1c0.5-1.1-0.1-2.3-2-2.4c-1.9-0.2-2.3,0.6-2.7,2.1c-0.3,1.3,0.7-1.3,2.3-0.6
		C124,134.1,124.2,134.1,125.2,134.1z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M110.3,133.5c0,0,0,6.6,1.7,8.6c0.6,0.7,4.4,1.9,5.6-2.1c1.3-4.1,3.9-7.2,3.9-7.2"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M127.6,117.8c1.7-1,3.2-1.3,2.5-1.9c-0.7-0.6-2.7-1.8-3.7-0.3C124.8,118,125.3,119.2,127.6,117.8z"
      />
      <path
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M109.1,120c-1,0-0.4-0.4-2.3-1s-3.5-1.6-2.8-2.2s2.8-1.1,4.2,0C110,118.1,110.2,120,109.1,120z"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M105.5,152.1"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M105.5,152.1c0,0,5.5,0.7,9.9,0.8c4.4,0.1,8.5-0.4,8.5-0.4"
      />

      <path
        display="inline"
        fill="#FFFFFF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M234.3,170.1c-1.9,1.6-14.2,3.6-18,4.4c-3.8,0.8-5.3,2.1-6.2,2.2c-0.9,0.1-0.6-3.8-0.6-3.8"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="214.3"
        y1="175.2"
        x2="214.3"
        y2="171.9"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="219.9"
        y1="170.6"
        x2="219.7"
        y2="173.5"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="225.4"
        y1="169.8"
        x2="225.4"
        y2="172.9"
      />

      <line
        display="inline"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="229.5"
        y1="168.9"
        x2="229.7"
        y2="172"
      />
    </g>
  </svg>
);

const BeavisAndButthead = styled(BeavisAndButtheadIllustration)<{
  chortle: boolean;
}>`
  transform-style: preserve-3d;
  backface-visibility: hidden;
  will-change: opacity;

  opacity: ${(props) => (props.chortle ? 1 : 0)};
  transition: opacity 0.5s ease-out;

  #chortle1 {
    opacity: 0;
    animation: chort1 1.3s steps(1) infinite;
  }

  #chortle2 {
    opacity: 0;
    animation: chort2 1.3s steps(1) infinite;
  }

  #chortle3 {
    opacity: 0;
    animation: chort3 1.3s steps(1) infinite;
  }

  #chortle4 {
    opacity: 0;
    animation: chort4 1.3s steps(1) infinite;
  }

  #chortle5 {
    opacity: 0;
    animation: chort5 1.3s steps(1) infinite;
  }

  #chortle6 {
    opacity: 0;
    animation: chort6 1.3s steps(1) infinite;
  }

  #chortle7 {
    opacity: 0;
    animation: chort7 1.3s steps(1) infinite;
  }

  #chortle8 {
    opacity: 0;
    animation: chort8 1.3s steps(1) infinite;
  }

  #chortle9 {
    opacity: 0;
    animation: chort9 1.3s steps(1) infinite;
  }

  #chortle10 {
    opacity: 0;
    animation: chort10 1.3s steps(1) infinite;
  }

  @keyframes chort1 {
    0% {
      opacity: 1;
    }
    10% {
      opacity: 0;
    }
  }

  @keyframes chort2 {
    10% {
      opacity: 1;
    }
    20% {
      opacity: 0;
    }
  }

  @keyframes chort3 {
    20% {
      opacity: 1;
    }
    30% {
      opacity: 0;
    }
  }

  @keyframes chort4 {
    30% {
      opacity: 1;
    }
    40% {
      opacity: 0;
    }
  }

  @keyframes chort5 {
    40% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }

  @keyframes chort6 {
    50% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
  }

  @keyframes chort7 {
    60% {
      opacity: 1;
    }
    70% {
      opacity: 0;
    }
  }

  @keyframes chort8 {
    70% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
  }

  @keyframes chort9 {
    80% {
      opacity: 1;
    }
    90% {
      opacity: 0;
    }
  }

  @keyframes chort10 {
    90% {
      opacity: 1;
    }
  }
`;

export default BeavisAndButthead;
