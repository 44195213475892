import React from 'react';

const ChartBackground = () => (
  <g>
    <g>
      <polyline strokeWidth="1.5" fill="none" stroke="#AAAAAA" points="1.2,5.4 25.5,5.4 25.5,168.2 1.2,168.2 		"/>
    </g>
    <g>
      <line strokeWidth="1.5" fill="none" stroke="#AAAAAA" x1="17.4" y1="30.9" x2="25.5" y2="30.9"/>
      <line strokeWidth="1.5" fill="none" stroke="#AAAAAA" x1="17.4" y1="86.4" x2="25.5" y2="86.4"/>
      <line strokeWidth="1.5" fill="none" stroke="#AAAAAA" x1="9.3" y1="58.6" x2="25.5" y2="58.6"/>
      <line strokeWidth="1.5" fill="none" stroke="#AAAAAA" x1="9.3" y1="114.2" x2="25.5" y2="114.2"/>
      <line strokeWidth="1.5" fill="none" stroke="#AAAAAA" x1="17.4" y1="141.9" x2="25.5" y2="141.9"/>
    </g>
    <g opacity="0.5">
      <circle fill="#888888" cx="42.7" cy="68.6" r="1.4"/>
      <circle fill="#888888" cx="63.6" cy="68.6" r="1.4"/>
      <circle fill="#888888" cx="84.5" cy="68.6" r="1.4"/>
      <circle fill="#888888" cx="105.4" cy="68.6" r="1.4"/>
      <circle fill="#888888" cx="126.3" cy="68.6" r="1.4"/>
      <circle fill="#888888" cx="147.1" cy="68.6" r="1.4"/>
      <circle fill="#888888" cx="42.7" cy="47.8" r="1.4"/>
      <circle fill="#888888" cx="63.6" cy="47.8" r="1.4"/>
      <circle fill="#888888" cx="84.5" cy="47.8" r="1.4"/>
      <circle fill="#888888" cx="105.4" cy="47.8" r="1.4"/>
      <circle fill="#888888" cx="126.3" cy="47.8" r="1.4"/>
      <circle fill="#888888" cx="147.1" cy="47.8" r="1.4"/>
      <circle fill="#888888" cx="42.7" cy="26.9" r="1.4"/>
      <circle fill="#888888" cx="63.6" cy="26.9" r="1.4"/>
      <circle fill="#888888" cx="84.5" cy="26.9" r="1.4"/>
      <circle fill="#888888" cx="105.4" cy="26.9" r="1.4"/>
      <circle fill="#888888" cx="126.3" cy="26.9" r="1.4"/>
      <circle fill="#888888" cx="147.1" cy="26.9" r="1.4"/>
      <circle fill="#888888" cx="42.7" cy="6" r="1.4"/>
      <circle fill="#888888" cx="63.6" cy="6" r="1.4"/>
      <circle fill="#888888" cx="84.5" cy="6" r="1.4"/>
      <circle fill="#888888" cx="105.4" cy="6" r="1.4"/>
      <circle fill="#888888" cx="126.3" cy="6" r="1.4"/>
      <circle fill="#888888" cx="147.1" cy="6" r="1.4"/>
      <circle fill="#888888" cx="168" cy="68.6" r="1.4"/>
      <circle fill="#888888" cx="188.9" cy="68.6" r="1.4"/>
      <circle fill="#888888" cx="209.8" cy="68.6" r="1.4"/>
      <circle fill="#888888" cx="230.7" cy="68.6" r="1.4"/>
      <circle fill="#888888" cx="251.5" cy="68.6" r="1.4"/>
      <circle fill="#888888" cx="168" cy="47.8" r="1.4"/>
      <circle fill="#888888" cx="188.9" cy="47.8" r="1.4"/>
      <circle fill="#888888" cx="209.8" cy="47.8" r="1.4"/>
      <circle fill="#888888" cx="230.7" cy="47.8" r="1.4"/>
      <circle fill="#888888" cx="251.5" cy="47.8" r="1.4"/>
      <circle fill="#888888" cx="168" cy="26.9" r="1.4"/>
      <circle fill="#888888" cx="188.9" cy="26.9" r="1.4"/>
      <circle fill="#888888" cx="209.8" cy="26.9" r="1.4"/>
      <circle fill="#888888" cx="230.7" cy="26.9" r="1.4"/>
      <circle fill="#888888" cx="251.5" cy="26.9" r="1.4"/>
      <circle fill="#888888" cx="168" cy="6" r="1.4"/>
      <circle fill="#888888" cx="188.9" cy="6" r="1.4"/>
      <circle fill="#888888" cx="209.8" cy="6" r="1.4"/>
      <circle fill="#888888" cx="230.7" cy="6" r="1.4"/>
      <circle fill="#888888" cx="251.5" cy="6" r="1.4"/>
      <circle fill="#888888" cx="272.4" cy="68.6" r="1.4"/>
      <circle fill="#888888" cx="293.3" cy="68.6" r="1.4"/>
      <circle fill="#888888" cx="314.2" cy="68.6" r="1.4"/>
      <circle fill="#888888" cx="335.1" cy="68.6" r="1.4"/>
      <circle fill="#888888" cx="355.9" cy="68.6" r="1.4"/>
      <circle fill="#888888" cx="272.4" cy="47.8" r="1.4"/>
      <circle fill="#888888" cx="293.3" cy="47.8" r="1.4"/>
      <circle fill="#888888" cx="314.2" cy="47.8" r="1.4"/>
      <circle fill="#888888" cx="335.1" cy="47.8" r="1.4"/>
      <circle fill="#888888" cx="355.9" cy="47.8" r="1.4"/>
      <circle fill="#888888" cx="272.4" cy="26.9" r="1.4"/>
      <circle fill="#888888" cx="293.3" cy="26.9" r="1.4"/>
      <circle fill="#888888" cx="314.2" cy="26.9" r="1.4"/>
      <circle fill="#888888" cx="335.1" cy="26.9" r="1.4"/>
      <circle fill="#888888" cx="355.9" cy="26.9" r="1.4"/>
      <circle fill="#888888" cx="272.4" cy="6" r="1.4"/>
      <circle fill="#888888" cx="293.3" cy="6" r="1.4"/>
      <circle fill="#888888" cx="314.2" cy="6" r="1.4"/>
      <circle fill="#888888" cx="335.1" cy="6" r="1.4"/>
      <circle fill="#888888" cx="355.9" cy="6" r="1.4"/>
      <circle fill="#888888" cx="376.8" cy="68.6" r="1.4"/>
      <circle fill="#888888" cx="397.7" cy="68.6" r="1.4"/>
      <circle fill="#888888" cx="418.6" cy="68.6" r="1.4"/>
      <circle fill="#888888" cx="439.5" cy="68.6" r="1.4"/>
      <circle fill="#888888" cx="460.3" cy="68.6" r="1.4"/>
      <circle fill="#888888" cx="376.8" cy="47.8" r="1.4"/>
      <circle fill="#888888" cx="397.7" cy="47.8" r="1.4"/>
      <circle fill="#888888" cx="418.6" cy="47.8" r="1.4"/>
      <circle fill="#888888" cx="439.5" cy="47.8" r="1.4"/>
      <circle fill="#888888" cx="460.3" cy="47.8" r="1.4"/>
      <circle fill="#888888" cx="376.8" cy="26.9" r="1.4"/>
      <circle fill="#888888" cx="397.7" cy="26.9" r="1.4"/>
      <circle fill="#888888" cx="418.6" cy="26.9" r="1.4"/>
      <circle fill="#888888" cx="439.5" cy="26.9" r="1.4"/>
      <circle fill="#888888" cx="460.3" cy="26.9" r="1.4"/>
      <circle fill="#888888" cx="376.8" cy="6" r="1.4"/>
      <circle fill="#888888" cx="397.7" cy="6" r="1.4"/>
      <circle fill="#888888" cx="418.6" cy="6" r="1.4"/>
      <circle fill="#888888" cx="439.5" cy="6" r="1.4"/>
      <circle fill="#888888" cx="460.3" cy="6" r="1.4"/>
      <circle fill="#888888" cx="42.7" cy="131.3" r="1.4"/>
      <circle fill="#888888" cx="63.6" cy="131.3" r="1.4"/>
      <circle fill="#888888" cx="84.5" cy="131.3" r="1.4"/>
      <circle fill="#888888" cx="105.4" cy="131.3" r="1.4"/>
      <circle fill="#888888" cx="126.3" cy="131.3" r="1.4"/>
      <circle fill="#888888" cx="147.1" cy="131.3" r="1.4"/>
      <circle fill="#888888" cx="42.7" cy="110.4" r="1.4"/>
      <circle fill="#888888" cx="63.6" cy="110.4" r="1.4"/>
      <circle fill="#888888" cx="84.5" cy="110.4" r="1.4"/>
      <circle fill="#888888" cx="105.4" cy="110.4" r="1.4"/>
      <circle fill="#888888" cx="126.3" cy="110.4" r="1.4"/>
      <circle fill="#888888" cx="147.1" cy="110.4" r="1.4"/>
      <circle fill="#888888" cx="42.7" cy="89.5" r="1.4"/>
      <circle fill="#888888" cx="63.6" cy="89.5" r="1.4"/>
      <circle fill="#888888" cx="84.5" cy="89.5" r="1.4"/>
      <circle fill="#888888" cx="105.4" cy="89.5" r="1.4"/>
      <circle fill="#888888" cx="126.3" cy="89.5" r="1.4"/>
      <circle fill="#888888" cx="147.1" cy="89.5" r="1.4"/>
      <circle fill="#888888" cx="168" cy="131.3" r="1.4"/>
      <circle fill="#888888" cx="188.9" cy="131.3" r="1.4"/>
      <circle fill="#888888" cx="209.8" cy="131.3" r="1.4"/>
      <circle fill="#888888" cx="230.7" cy="131.3" r="1.4"/>
      <circle fill="#888888" cx="251.5" cy="131.3" r="1.4"/>
      <circle fill="#888888" cx="168" cy="110.4" r="1.4"/>
      <circle fill="#888888" cx="188.9" cy="110.4" r="1.4"/>
      <circle fill="#888888" cx="209.8" cy="110.4" r="1.4"/>
      <circle fill="#888888" cx="230.7" cy="110.4" r="1.4"/>
      <circle fill="#888888" cx="251.5" cy="110.4" r="1.4"/>
      <circle fill="#888888" cx="168" cy="89.5" r="1.4"/>
      <circle fill="#888888" cx="188.9" cy="89.5" r="1.4"/>
      <circle fill="#888888" cx="209.8" cy="89.5" r="1.4"/>
      <circle fill="#888888" cx="230.7" cy="89.5" r="1.4"/>
      <circle fill="#888888" cx="251.5" cy="89.5" r="1.4"/>
      <circle fill="#888888" cx="272.4" cy="131.3" r="1.4"/>
      <circle fill="#888888" cx="293.3" cy="131.3" r="1.4"/>
      <circle fill="#888888" cx="314.2" cy="131.3" r="1.4"/>
      <circle fill="#888888" cx="335.1" cy="131.3" r="1.4"/>
      <circle fill="#888888" cx="355.9" cy="131.3" r="1.4"/>
      <circle fill="#888888" cx="272.4" cy="110.4" r="1.4"/>
      <circle fill="#888888" cx="293.3" cy="110.4" r="1.4"/>
      <circle fill="#888888" cx="314.2" cy="110.4" r="1.4"/>
      <circle fill="#888888" cx="335.1" cy="110.4" r="1.4"/>
      <circle fill="#888888" cx="355.9" cy="110.4" r="1.4"/>
      <circle fill="#888888" cx="272.4" cy="89.5" r="1.4"/>
      <circle fill="#888888" cx="293.3" cy="89.5" r="1.4"/>
      <circle fill="#888888" cx="314.2" cy="89.5" r="1.4"/>
      <circle fill="#888888" cx="335.1" cy="89.5" r="1.4"/>
      <circle fill="#888888" cx="355.9" cy="89.5" r="1.4"/>
      <circle fill="#888888" cx="376.8" cy="131.3" r="1.4"/>
      <circle fill="#888888" cx="397.7" cy="131.3" r="1.4"/>
      <circle fill="#888888" cx="418.6" cy="131.3" r="1.4"/>
      <circle fill="#888888" cx="439.5" cy="131.3" r="1.4"/>
      <circle fill="#888888" cx="460.3" cy="131.3" r="1.4"/>
      <circle fill="#888888" cx="376.8" cy="110.4" r="1.4"/>
      <circle fill="#888888" cx="397.7" cy="110.4" r="1.4"/>
      <circle fill="#888888" cx="418.6" cy="110.4" r="1.4"/>
      <circle fill="#888888" cx="439.5" cy="110.4" r="1.4"/>
      <circle fill="#888888" cx="460.3" cy="110.4" r="1.4"/>
      <circle fill="#888888" cx="376.8" cy="89.5" r="1.4"/>
      <circle fill="#888888" cx="397.7" cy="89.5" r="1.4"/>
      <circle fill="#888888" cx="418.6" cy="89.5" r="1.4"/>
      <circle fill="#888888" cx="439.5" cy="89.5" r="1.4"/>
      <circle fill="#888888" cx="460.3" cy="89.5" r="1.4"/>
      <circle fill="#888888" cx="481.2" cy="68.6" r="1.4"/>
      <circle fill="#888888" cx="502.1" cy="68.6" r="1.4"/>
      <circle fill="#888888" cx="523" cy="68.6" r="1.4"/>
      <circle fill="#888888" cx="543.9" cy="68.6" r="1.4"/>
      <circle fill="#888888" cx="564.7" cy="68.6" r="1.4"/>
      <circle fill="#888888" cx="481.2" cy="47.8" r="1.4"/>
      <circle fill="#888888" cx="502.1" cy="47.8" r="1.4"/>
      <circle fill="#888888" cx="523" cy="47.8" r="1.4"/>
      <circle fill="#888888" cx="543.9" cy="47.8" r="1.4"/>
      <circle fill="#888888" cx="564.7" cy="47.8" r="1.4"/>
      <circle fill="#888888" cx="481.2" cy="26.9" r="1.4"/>
      <circle fill="#888888" cx="502.1" cy="26.9" r="1.4"/>
      <circle fill="#888888" cx="523" cy="26.9" r="1.4"/>
      <circle fill="#888888" cx="543.9" cy="26.9" r="1.4"/>
      <circle fill="#888888" cx="564.7" cy="26.9" r="1.4"/>
      <circle fill="#888888" cx="481.2" cy="6" r="1.4"/>
      <circle fill="#888888" cx="502.1" cy="6" r="1.4"/>
      <circle fill="#888888" cx="523" cy="6" r="1.4"/>
      <circle fill="#888888" cx="543.9" cy="6" r="1.4"/>
      <circle fill="#888888" cx="564.7" cy="6" r="1.4"/>
      <circle fill="#888888" cx="585.6" cy="68.6" r="1.4"/>
      <circle fill="#888888" cx="606.5" cy="68.6" r="1.4"/>
      <circle fill="#888888" cx="627.4" cy="68.6" r="1.4"/>
      <circle fill="#888888" cx="648.3" cy="68.6" r="1.4"/>
      <circle fill="#888888" cx="585.6" cy="47.8" r="1.4"/>
      <circle fill="#888888" cx="606.5" cy="47.8" r="1.4"/>
      <circle fill="#888888" cx="627.4" cy="47.8" r="1.4"/>
      <circle fill="#888888" cx="648.3" cy="47.8" r="1.4"/>
      <circle fill="#888888" cx="585.6" cy="26.9" r="1.4"/>
      <circle fill="#888888" cx="606.5" cy="26.9" r="1.4"/>
      <circle fill="#888888" cx="627.4" cy="26.9" r="1.4"/>
      <circle fill="#888888" cx="648.3" cy="26.9" r="1.4"/>
      <circle fill="#888888" cx="585.6" cy="6" r="1.4"/>
      <circle fill="#888888" cx="606.5" cy="6" r="1.4"/>
      <circle fill="#888888" cx="627.4" cy="6" r="1.4"/>
      <circle fill="#888888" cx="648.3" cy="6" r="1.4"/>
      <circle fill="#888888" cx="481.2" cy="131.3" r="1.4"/>
      <circle fill="#888888" cx="502.1" cy="131.3" r="1.4"/>
      <circle fill="#888888" cx="523" cy="131.3" r="1.4"/>
      <circle fill="#888888" cx="543.9" cy="131.3" r="1.4"/>
      <circle fill="#888888" cx="564.7" cy="131.3" r="1.4"/>
      <circle fill="#888888" cx="481.2" cy="110.4" r="1.4"/>
      <circle fill="#888888" cx="502.1" cy="110.4" r="1.4"/>
      <circle fill="#888888" cx="523" cy="110.4" r="1.4"/>
      <circle fill="#888888" cx="543.9" cy="110.4" r="1.4"/>
      <circle fill="#888888" cx="564.7" cy="110.4" r="1.4"/>
      <circle fill="#888888" cx="481.2" cy="89.5" r="1.4"/>
      <circle fill="#888888" cx="502.1" cy="89.5" r="1.4"/>
      <circle fill="#888888" cx="523" cy="89.5" r="1.4"/>
      <circle fill="#888888" cx="543.9" cy="89.5" r="1.4"/>
      <circle fill="#888888" cx="564.7" cy="89.5" r="1.4"/>
      <circle fill="#888888" cx="42.7" cy="173" r="1.4"/>
      <circle fill="#888888" cx="63.6" cy="173" r="1.4"/>
      <circle fill="#888888" cx="84.5" cy="173" r="1.4"/>
      <circle fill="#888888" cx="105.4" cy="173" r="1.4"/>
      <circle fill="#888888" cx="126.3" cy="173" r="1.4"/>
      <circle fill="#888888" cx="147.1" cy="173" r="1.4"/>
      <circle fill="#888888" cx="42.7" cy="152.2" r="1.4"/>
      <circle fill="#888888" cx="63.6" cy="152.2" r="1.4"/>
      <circle fill="#888888" cx="84.5" cy="152.2" r="1.4"/>
      <circle fill="#888888" cx="105.4" cy="152.2" r="1.4"/>
      <circle fill="#888888" cx="126.3" cy="152.2" r="1.4"/>
      <circle fill="#888888" cx="147.1" cy="152.2" r="1.4"/>
      <circle fill="#888888" cx="168" cy="173" r="1.4"/>
      <circle fill="#888888" cx="188.9" cy="173" r="1.4"/>
      <circle fill="#888888" cx="209.8" cy="173" r="1.4"/>
      <circle fill="#888888" cx="230.7" cy="173" r="1.4"/>
      <circle fill="#888888" cx="251.5" cy="173" r="1.4"/>
      <circle fill="#888888" cx="168" cy="152.2" r="1.4"/>
      <circle fill="#888888" cx="188.9" cy="152.2" r="1.4"/>
      <circle fill="#888888" cx="209.8" cy="152.2" r="1.4"/>
      <circle fill="#888888" cx="230.7" cy="152.2" r="1.4"/>
      <circle fill="#888888" cx="251.5" cy="152.2" r="1.4"/>
      <circle fill="#888888" cx="272.4" cy="173" r="1.4"/>
      <circle fill="#888888" cx="293.3" cy="173" r="1.4"/>
      <circle fill="#888888" cx="314.2" cy="173" r="1.4"/>
      <circle fill="#888888" cx="335.1" cy="173" r="1.4"/>
      <circle fill="#888888" cx="355.9" cy="173" r="1.4"/>
      <circle fill="#888888" cx="272.4" cy="152.2" r="1.4"/>
      <circle fill="#888888" cx="293.3" cy="152.2" r="1.4"/>
      <circle fill="#888888" cx="314.2" cy="152.2" r="1.4"/>
      <circle fill="#888888" cx="335.1" cy="152.2" r="1.4"/>
      <circle fill="#888888" cx="355.9" cy="152.2" r="1.4"/>
      <circle fill="#888888" cx="376.8" cy="173" r="1.4"/>
      <circle fill="#888888" cx="397.7" cy="173" r="1.4"/>
      <circle fill="#888888" cx="418.6" cy="173" r="1.4"/>
      <circle fill="#888888" cx="439.5" cy="173" r="1.4"/>
      <circle fill="#888888" cx="460.3" cy="173" r="1.4"/>
      <circle fill="#888888" cx="376.8" cy="152.2" r="1.4"/>
      <circle fill="#888888" cx="397.7" cy="152.2" r="1.4"/>
      <circle fill="#888888" cx="418.6" cy="152.2" r="1.4"/>
      <circle fill="#888888" cx="439.5" cy="152.2" r="1.4"/>
      <circle fill="#888888" cx="460.3" cy="152.2" r="1.4"/>
      <circle fill="#888888" cx="481.2" cy="173" r="1.4"/>
      <circle fill="#888888" cx="502.1" cy="173" r="1.4"/>
      <circle fill="#888888" cx="523" cy="173" r="1.4"/>
      <circle fill="#888888" cx="543.9" cy="173" r="1.4"/>
      <circle fill="#888888" cx="564.7" cy="173" r="1.4"/>
      <circle fill="#888888" cx="481.2" cy="152.2" r="1.4"/>
      <circle fill="#888888" cx="502.1" cy="152.2" r="1.4"/>
      <circle fill="#888888" cx="523" cy="152.2" r="1.4"/>
      <circle fill="#888888" cx="543.9" cy="152.2" r="1.4"/>
      <circle fill="#888888" cx="564.7" cy="152.2" r="1.4"/>
      <circle fill="#888888" cx="585.6" cy="173" r="1.4"/>
      <circle fill="#888888" cx="606.5" cy="173" r="1.4"/>
      <circle fill="#888888" cx="627.4" cy="173" r="1.4"/>
      <circle fill="#888888" cx="648.3" cy="173" r="1.4"/>
      <circle fill="#888888" cx="585.6" cy="152.2" r="1.4"/>
      <circle fill="#888888" cx="606.5" cy="152.2" r="1.4"/>
      <circle fill="#888888" cx="627.4" cy="152.2" r="1.4"/>
      <circle fill="#888888" cx="648.3" cy="152.2" r="1.4"/>
      <circle fill="#888888" cx="585.6" cy="131.3" r="1.4"/>
      <circle fill="#888888" cx="606.5" cy="131.3" r="1.4"/>
      <circle fill="#888888" cx="627.4" cy="131.3" r="1.4"/>
      <circle fill="#888888" cx="648.3" cy="131.3" r="1.4"/>
      <circle fill="#888888" cx="585.6" cy="110.4" r="1.4"/>
      <circle fill="#888888" cx="606.5" cy="110.4" r="1.4"/>
      <circle fill="#888888" cx="627.4" cy="110.4" r="1.4"/>
      <circle fill="#888888" cx="648.3" cy="110.4" r="1.4"/>
      <circle fill="#888888" cx="585.6" cy="89.5" r="1.4"/>
      <circle fill="#888888" cx="606.5" cy="89.5" r="1.4"/>
      <circle fill="#888888" cx="627.4" cy="89.5" r="1.4"/>
      <circle fill="#888888" cx="648.3" cy="89.5" r="1.4"/>
    </g>
  </g>
);

export default ChartBackground;
