import { Tabs, Tab } from "../../../../src/components/Tabs";
import { Definition, Property, Value } from "../../../../src/components/DefinitionList";
import InvestmentCastingProcess from "../../../../src/articles/components/investment-casting-process";
import * as React from 'react';
export default {
  Tabs,
  Tab,
  Definition,
  Property,
  Value,
  InvestmentCastingProcess,
  React
};