import GaugeExample from "../../../../src/articles/components/gauge-example";
import ChainsawExample from "../../../../src/articles/components/chainsaw-example";
import TapeExample from "../../../../src/articles/components/tape-example";
import ExperimentExample from "../../../../src/articles/components/experiment-example";
import ClockExample from "../../../../src/articles/components/clock-example";
import ControlledExample from "../../../../src/articles/components/controlled-example";
import * as React from 'react';
export default {
  GaugeExample,
  ChainsawExample,
  TapeExample,
  ExperimentExample,
  ClockExample,
  ControlledExample,
  React
};