import AuthenticationProcess from "../../../../src/articles/components/microsoft-auth-process";
import AuthorizationGrant from "../../../../src/articles/components/authorization-code-grant";
import Code from "../../../../src/components/Post/Code";
import { Tabs, Tab } from "../../../../src/components/Tabs";
import * as React from 'react';
export default {
  AuthenticationProcess,
  AuthorizationGrant,
  Code,
  Tabs,
  Tab,
  React
};